import { Routes, Route, useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import {Helmet} from "react-helmet";

import Home from "./routes/home/home";

import Navigation from "./components/navigation/navigation";
import AuthIllustration from "./components/auth/auth.component";

import Login from "./routes/auth/login/login.component";
import Register from "./routes/auth/register/register.component";
import ForgotPassword from "./routes/auth/forgot-password/forgot-password.component";

import Dashboard from "./routes/dashboard/dashboard.component";
import Monitoring from "./routes/monitoring/monitoring.component";
import Settings from "./routes/settings/settings.component";
import NotFound from "./routes/not-found/not-found.component";

// protected route
import ProtectedRoute from "./routes/protected-route/protected-route.component";
import UnprotectedRoute from "./routes/unprotected-route/unprotected-route.component";
import AccountActivation from "./routes/auth/account-activation/account-activation.component";
import ResetPassword from "./routes/auth/forgot-password/reset-password.component";


const App = () => {

  
	/**************************************************************************/
    // Redux
	/**************************************************************************/
    const token = useSelector((state) => state.user.currentUser.token);

    const navigate = useNavigate();


    useEffect(() => {
        const location = window.location.pathname;
        if ( location === '/dashboard' || location === '/settings' || location === '/monitoring' ) {
            if ( token === null || token === '' || token === undefined || !token ) 
            {
                navigate('/login')
            }
        }
    })

    
    return (
        <Routes>
            <Route path='/' element={
                <Fragment>
                    <Helmet>
                        <title>Heimdall - Protector of your network realm</title>
                        <meta property="og:title" content="Heimdall - Protector of your network realm" />
                    </Helmet>
                    <Home />
                </Fragment>
            } />

            <Route element={<UnprotectedRoute><AuthIllustration /></UnprotectedRoute>}>

                {/* Forgot Password */}
                <Route path='/forgot-password' element={
                    <UnprotectedRoute>
                        <Helmet>
                            <title>Forgot Password | Heimdall - Protector of your network realm</title>
                            <meta property="og:title" content="Forgot Password - Heimdall - Protector of your network realm" />
                        </Helmet>
                        <ForgotPassword />
                    </UnprotectedRoute>
                } />


                {/* Login */}
                <Route path='/login' element={
                    <UnprotectedRoute>
                        <Helmet>
                            <title>Login | Heimdall - Protector of your network realm</title>
                            <meta property="og:title" content="Login - Heimdall - Protector of your network realm" />
                        </Helmet>
                        <Login />
                    </UnprotectedRoute>
                } />


                {/* Activation password */}
                <Route path='/activate-account/:activation_token' element={
                    <UnprotectedRoute><AccountActivation /></UnprotectedRoute>
                } />
                
                
                {/* Reset password */}
                <Route path='/reset-password/:token' element={
                    <UnprotectedRoute><ResetPassword /></UnprotectedRoute>
                } />


                {/* Register */}
                <Route path='/register' element={
                    <UnprotectedRoute>
                        <Helmet>
                            <title>Register | Heimdall - Protector of your network realm</title>
                            <meta property="og:title" content="Register - Heimdall - Protector of your network realm" />
                        </Helmet>
                        <Register />
                    </UnprotectedRoute>
                } />
            </Route>

            { /* Dashboard */  }
            <Route element={<ProtectedRoute><Navigation /></ProtectedRoute>}>
                <Route path="/dashboard" element={
                    <Fragment>
                        <Helmet>
                            <title>Dashboard | Heimdall - Protector of your network realm</title>
                            <meta property="og:title" content="Dashboard - Heimdall - Protector of your network realm" />
                            <meta name="description" content="Protector of your network realm" />
                        </Helmet>
                        <Dashboard />
                    </Fragment>
                } />
                <Route path="/settings" element={
                    <Fragment>
                        <Helmet>
                            <title>Settings | Heimdall - Protector of your network realm</title>
                            <meta property="og:title" content="Dashboard - Heimdall - Protector of your network realm" />
                            <meta name="description" content="Protector of your network realm" />
                        </Helmet>
                        <Settings />
                    </Fragment>
                } />
                <Route path="/monitoring/:uuid" element={<Monitoring />} />
            </Route>
            
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default App;
