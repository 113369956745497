import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import HookLog from './hook-log.component';

const HookLogs = () => {
	const ITEMS_PER_PAGE = 10;
	
	const hookLogs = useSelector( state => state.user.currentUser.settings.logs );

	// Pagination
	const [ pagination, setPagination ] = useState({
		maxPage: 0,
		currentPage: 1,
		itemsPerPage: ITEMS_PER_PAGE,
		logs: []
	});

	// initializing after geting logs
	useEffect( () => {
		if ( hookLogs.length > 0 && hookLogs.length > pagination.itemsPerPage )
		{
			setPagination({
				...pagination,
				maxPage: Math.ceil(hookLogs.length / pagination.itemsPerPage),
				logs: hookLogs.slice(0, pagination.itemsPerPage),
			});
		} else {
			setPagination({
				...pagination,
				logs: hookLogs,
			});
		}
	}, [hookLogs] )

	
	// Handle pagination page change 
	const handlePaginationChange = (page) => {
		let sliceTo = page * pagination.itemsPerPage;
		let sliceFrom = sliceTo - pagination.itemsPerPage;

		setPagination({
			...pagination,
			currentPage: page,
			logs: hookLogs.slice(sliceFrom, sliceTo),
		});
	}
	
	return (
		<div className="col-xs-12 col-sm-12 col-lg-12 mt-lg-0">
			<div className="row mt-5">
				<div className="col-xs-12 col-sm-12 col-lg-12 mt-lg-0">
					<h5 className='subheading'>Hook Logs</h5>

					{ hookLogs.length > 0 ? (
					<div className="hook-logs">
						<div className="head">
							<p>Entry</p>
							<p>Response</p>
							<p>Date</p>
							<p>Status</p>
						</div>
						<ul className="body">
							{ pagination.logs.map( (log, index) =>
								<HookLog log={log} index={index} key={index} />
							) }
						</ul>

						{ pagination.maxPage ? (
							<ul className="pagination mt-5">
								<li className={`paginate_button page-item previous ${pagination.currentPage === 1 ? 'disabled' : ''}`} id="kt_table_users_previous">
									<div onClick={() => handlePaginationChange(pagination.currentPage-1)} className="page-link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
									</div>
								</li>
								{
									[...Array(pagination.maxPage)].map( (x, i) =>
										<li key={i} className={`paginate_button page-item ${ pagination.currentPage === i+1 ? 'active' : '' }`}>
											<div onClick={() => handlePaginationChange(i+1)} className="page-link">{i+1}</div>
										</li>
									)
								}
								<li className={`paginate_button page-item next ${pagination.currentPage === pagination.maxPage ? 'disabled' : ''}`} id="kt_table_users_next">
									<div onClick={() => handlePaginationChange(pagination.currentPage+1)} className="page-link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
									</div>
								</li>
							</ul>
						) : ''}
					</div>) : <p className='text-center'>There are no logs found</p> }
				</div>
			</div>
		</div>
	)
}

export default HookLogs