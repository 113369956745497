import React, { useContext, useEffect } from 'react';
import { useState } from "react";
import moment from 'moment';


// Importing Icons
import {ReactComponent as RecheckIcon} from '../../assets/images/recheck.svg'
import {ReactComponent as DeleteIcon} from '../../assets/images/delete.svg'
import { ReactComponent as Harmless } from '../../assets/images/harmless.svg';
import { ReactComponent as Suspicious } from '../../assets/images/suspicious.svg';
import { ReactComponent as Malicious } from '../../assets/images/malicious.svg';
import { ReactComponent as Undetected } from '../../assets/images/undetected.svg';
import { EntriesContext } from '../../context/entries.context';
import { Link, useNavigate } from 'react-router-dom';
import { LoaderContext } from '../../context/loader.context';
import Loader from '../loader/loader.component';

const Entry = ({ record, isChecked, handleClick, height }) => {

	// use state
	const [ winWidth, setWinWidth ] = useState(window.innerWidth);
	const [ tooltipText, setTooltipText ] = useState('');

	// destruct entry 
	const { uuid, entry, updated_at } = record;

	var updatedAt = moment(updated_at).format();
	const date = moment(updatedAt).format("D MMMM, YYYY");
	const time = moment(updatedAt).format("H:mm");
	
	// Interval for rechecking entry if there are no information
	const RECHECK_INTERVAL = 5000;

	// Navigate
	const navigate = useNavigate();


	// Context
	const { deleteEntry, recheckEntry, quickEntryRecheck } = useContext(EntriesContext);
	const { recheckEntryLoader } = useContext(LoaderContext);
	const { setBoxActive } = useContext(LoaderContext)	


	// State
	const [listOpen, setListOpen] = useState(false);

	useEffect(() => {
		if ( listOpen ) {
			document.body.addEventListener('click', () => {
				setListOpen(false);
			});
		}
	})


	// handle opening settings on click
	const handleSettings = (e) => {
		e.stopPropagation()
		setListOpen(!listOpen);
	}


	// handle entry recheck
	const handleRecheck = () => {
		recheckEntry(uuid)
	}


	// deleting entries
	const handleDelete = () => {
		deleteEntry(uuid);
		setBoxActive('')
	}

	// redirect to single entry information ( /monitoring/... )
	const handleClickEntry = () => {
		if (record.data?.stats?.malicious > 0 || (record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious > 0) || (record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious === 0 && record.data?.stats?.harmless > 0) ) 
			navigate('/monitoring/'+record.uuid);
	}


	// set timer for checking entry
	useEffect( () => 
	{
		if ( Array.isArray(record?.data) ) {
			// set interval for rechecking the entry data
			var interval = setInterval(() => {
				quickEntryRecheck(uuid);
			}, RECHECK_INTERVAL)
		}

		// clear interval after recheck
		return () => clearInterval(interval);
	}, [record])

	
	useEffect(() => {
		setTooltipText(
			(record.data?.stats?.malicious > 0) ?
				'Malicious' :
			(record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious > 0) ? 
				'Suspicious' : 
			(record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious === 0 && record.data?.stats?.harmless > 0) ? 
				'Harmless' : 
			'Undetected'
		);
	}, [])
	

	return (
		<>
			{ (window.innerWidth > 767) ? (<div className={`tr desktop ${tooltipText}`}>
				<div className="entry-wrap">
					<div className='check' onClick={e => e.stopPropagation()}>
						<input onChange={handleClick} checked={isChecked} type="checkbox" className='checkbox' id={`${record.uuid}`} />
						<label htmlFor={`${record.uuid}`} className="check-label"></label>
					</div>
					<Link to={`${record.data?.stats?.malicious > 0 || (record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious > 0) || (record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious === 0 && record.data?.stats?.harmless > 0) ? `/monitoring/${record.uuid}` : `#` }`} className={`location ${entry.length > 18 ? `smaller` : ``}`}>{entry}</Link>
					<div className='status with-tooltip' data-tooltip={tooltipText}>
					{ 
						( !Array.isArray(record?.data) ) ? (
							(record.data?.stats?.malicious > 0) ? 
								<Malicious /> :
							(record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious > 0) ? 
								<Suspicious /> : 
							(record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious === 0 && record.data?.stats?.harmless > 0) ? 
								<Harmless /> : 
								<Undetected />
						) : <Loader />
					}
					</div>
					<div className='harmless'>{(record.data?.stats?.harmless !== undefined) ? record.data?.stats?.harmless : '...' }</div>
					<div className='suspicious'>{(record.data?.stats?.suspicious !== undefined) ? record.data?.stats?.suspicious : '...' }</div>
					<div className='malicious'>{(record.data?.stats?.malicious !== undefined) ? record.data?.stats?.malicious : '...' }</div>
					<div className='undetected'>{(record.data?.stats?.undetected !== undefined) ? record.data?.stats?.undetected : '...' }</div>
					<div className='updated'>{date}<span>{time}</span></div>
					<div className='trash' onClick={handleSettings}>
						<span></span>
						<span></span>
						<span></span>
						{ 
							listOpen && (
								<ul className="dropdown">
									<li onClick={handleRecheck}><RecheckIcon />Recheck</li>
									<li onClick={handleDelete}><DeleteIcon />Delete</li>
								</ul>
							)
						}
					</div>
				</div>
				{ recheckEntryLoader === record.uuid && <Loader /> }
			</div>) :
			(<div className='tr mobile'>
				<div className="entry-wrap">
					<div className='top'>
						<div className='check'>
							<input onChange={handleClick} checked={isChecked} type="checkbox" className='checkbox' id={`${record.uuid}`} />
							<label htmlFor={`${record.uuid}`} className="check-label"></label>
						</div>
						<div className='location' onClick={handleClickEntry}>{entry}</div>
						<div className='status'>
						{ 
							(record.data?.stats?.malicious > 0) ? 
								<Malicious /> :
							(record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious > 0) ? 
								<Suspicious /> : 
							(record.data?.stats?.malicious === 0 && record.data?.stats?.suspicious === 0 && record.data?.stats?.harmless > 0) ? 
								<Harmless /> : 
								<Undetected />
						}
						</div>
						<div className='trash' onClick={handleSettings}>
							<span></span>
							<span></span>
							<span></span>
							{ 
								listOpen && (
									<ul className="dropdown">
										<li onClick={handleRecheck}><RecheckIcon />Recheck</li>
										<li onClick={handleDelete}><DeleteIcon />Delete</li>
									</ul>
								)
							}
						</div>
					</div>
					<div className='bottom'>
						<div className='harmless'>Harmless<br /><b>{(record.data?.stats?.harmless !== undefined) ? record.data?.stats?.harmless : '...'}</b></div>
						<div className='suspicious'>Suspicious<br /><b>{(record.data?.stats?.suspicious !== undefined) ? record.data?.stats?.suspicious : '...'}</b></div>
						<div className='malicious'>Malicious<br /><b>{(record.data?.stats?.malicious !== undefined) ? record.data?.stats?.malicious : '...'}</b></div>
						<div className='undetected'>Undetected<br /><b>{(record.data?.stats?.undetected !== undefined) ? record.data?.stats?.undetected : '...'}</b></div>
					</div>
				</div>
			</div>) }
		</>
	);
}

export default Entry;
