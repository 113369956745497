import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";


const ProtectedRoute = ({ children }) => {

    const isAuthenticated = useSelector((state) => state.user.currentUser.token);

    // Check if token (isAuthenticated) is valid
    if ( isAuthenticated && isAuthenticated !== null && isAuthenticated !== undefined ) {
        return children;
    } else {
        return <Navigate to='/login' />
    }

};

export default ProtectedRoute;