import { ACTION_TYPES } from './entries.types';

const INITIAL_STATE = {
	entries: [],
	stats: {
		harmless: 0,
		suspicious: 0,
		malicious: 0,
		undetected: 0,
	},
	toggleNewEntry: false,
	entriesPagination: [],
	entriesInformation: []
}

export const entriesReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch(type) {
		case ACTION_TYPES.SET_ENTRIES:
			return {
				...state,
				entries: payload
			}

		case ACTION_TYPES.ADD_NEW_ENTRY:
			const addNewEntryTMP = state.entries.filter((singleEntry) => {
				return singleEntry.uuid !== payload.uuid
			})

			let tmpentries = [payload, ...addNewEntryTMP];
			return {
				...state,
				entries: tmpentries
			}

		case ACTION_TYPES.REMOVE_ENTRY:
			const entries = state.entries.filter((singleEntry) => {
				return singleEntry.uuid !== payload
			})
			return {
				...state,
				entries: entries
			}

		case ACTION_TYPES.TOGGLE_NEW_ENTRY:
			return {
				...state,
				toggleNewEntry: payload
			}

		case ACTION_TYPES.ADD_NEW_ENTRY_INFORMATION:
			let tmp = state.entriesInformation;
			tmp[payload.uuid] = payload;

			return {
				...state,
				entriesInformation: tmp
			}

		case ACTION_TYPES.SET_ENTRIES_PAGINATION:
			return {
				...state,
				entriesPagination: payload
			}

		case ACTION_TYPES.SET_STATS:
			return {
				...state,
				stats: payload
			}

		default:
			return state;
	}
}
