import { useContext, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from '../store/user/user.action';
import { LoaderContext } from "./loader.context";
import React from 'react';
import { API_URL } from '../conf';

import { toast } from 'react-toastify';

// redux
import { removeCurrentUser } from "../store/user/user.action"
import { setEntries, setEntriesPagination } from "../store/entries/entries.action";
import { useEffect } from "react";
import { useState } from "react";


// as the actual value you want to access
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ resendActivationCode, setResendActivationCode ] = useState({
        status: false,
        email: '',
    });
    
    // Context
    const { setLoader, setScreenLoader } = useContext(LoaderContext);

    // Navigate
    const navigate = useNavigate();

    // Redux
    const dispatch = useDispatch();
	const token = useSelector((state) => state.user.currentUser.token);


    /*------------------------------------------------------------------------------------
    // Check if the localstorage is valid JWToken 
    ------------------------------------------------------------------------------------*/
    useEffect( () => {
        if ( token )
            authCheck(token, false);

        setResendActivationCode({status: false, email: ''});
    }, [token] )



    /*------------------------------------------------------------------------------------
    // Login Function: fetching token from API request
    ------------------------------------------------------------------------------------*/
    function authCheck(token, notficiation = true) {
        setScreenLoader(true);

        fetch(`${API_URL}/profile`, {
            method: "GET", 
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer "+localStorage.getItem('token'),
            },
        })
        .then((response) => response.json() )
        .then( (result) => {
            if ( result.status === 'error' )
            {
                // handle session expired
                dispatch(removeCurrentUser());
                setLoader(false);
                setScreenLoader(false);
                if (notficiation)
                    toast.error(result.message);
                // navigate('/login')
            } else {
                dispatch(setCurrentUser(result));
                setLoader(false);
                setScreenLoader(false);
            }
        },
        (error) => {
            dispatch(removeCurrentUser());
            setScreenLoader(false);
            setLoader(false);
            // navigate('/login')
        })
    }



    /*------------------------------------------------------------------------------------
    // Login Function: fetching token from API request
    ------------------------------------------------------------------------------------*/
    function authLogin(email, password) {
		setLoader(true);

        let data = {
            email: email,
            password: password,
        }

        fetch(`${API_URL}/login`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if ( response.status === 403 )
                setResendActivationCode({status: true, email: email});
            return response.json()
        })
        .then( (result) => {
                if ( result.status === 'success' ) 
                {
                    dispatch(setCurrentUser(result));
                    toast.success(result.message);
                    navigate('/dashboard');
                } else {
                    toast.error(result.message);
                }
        		setLoader(false);
            },
            (err) => {
                console.log(err);
                setLoader(false);
                toast.error('Something went wrong, please try again.');
            }
        )
    }


    /*------------------------------------------------------------------------------------
    // Account Activation Function: 
    ------------------------------------------------------------------------------------*/
    function accountActivation(activation_token) {
		setLoader(true);

        fetch(`${API_URL}/verify-email/${activation_token}`, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    return true;
                    toast.success(result.message);
                    // navigate('/login');
                } else {
                    toast.error(result.message);
                    // navigate('/login');
                }
        		setLoader(false);
            },
            (error) => {
        		setLoader(false);
                toast.error('Something went wrong, please try again.');
            }
        )
    }


    /*------------------------------------------------------------------------------------
    // Register Function: user registering with POST Request
    ------------------------------------------------------------------------------------*/
    function authRegister(email, password, password_confirmation) {
		setLoader(true);
        
        let data = {
            email: email,
            password: password,
            password_confirmation: password_confirmation,
        }

        fetch(`${API_URL}/register`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    toast.success(result.message);
                    navigate('/login');
                } else 
                    toast.error(result.message);
        		setLoader(false);
            },
            (error) => {
        		setLoader(false);
                toast.error('Something went wrong, please try again.');
            }
        )
    }


    /*------------------------------------------------------------------------------------
    // Logout Function: user logout with POST Request
    ------------------------------------------------------------------------------------*/
    function authLogout(userToken) {
        fetch(`${API_URL}/logout`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+userToken,
            },
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    dispatch(setEntries([]));
                    dispatch(setEntriesPagination([]));
		            dispatch(removeCurrentUser());
                    toast.success(result.message);
                    navigate('/login');
                } else {
                    toast.error(result.message);
                }
            },
            (error) => {
                toast.error('Something went wrong, please try again.');
            }
        )
    }


    /*------------------------------------------------------------------------------------
    // Handle Forgot Password
    ------------------------------------------------------------------------------------*/
    function authForgotPassword(email) {
		setLoader(true);
        fetch(`${API_URL}/forgot-password`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                email: email
            }),
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    navigate('/login');
                    toast.success(result.message);
                    setLoader(false);
                } else {
                    toast.error(result.message);
                    setLoader(false);
                }
            },
            (error) => {
                toast.error('Something went wrong, please try again.');
                setLoader(false);
            }
        )
    }


    /*------------------------------------------------------------------------------------
    // Reset Password
    ------------------------------------------------------------------------------------*/
    function authResetPassword(token, password, password_confirmation) {
		setLoader(true);

        let data = {
            token: token,
            password: password,
            password_confirmation: password_confirmation
        }

        fetch(`${API_URL}/reset-password`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    navigate('/login');
                    toast.success(result.message);
                    setLoader(false);
                } else {
                    toast.error(result.message);
                    setLoader(false);
                }
            },
            (error) => {
                toast.error('Something went wrong, please try again.');
                setLoader(false);
            }
        )
    }


    /*------------------------------------------------------------------------------------
    // Reset Password
    ------------------------------------------------------------------------------------*/
    function authResendActivationCode() {
		setLoader(true);

        let data = {
            email: resendActivationCode.email,
        }

        fetch(`${API_URL}/resend-activation-email`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    toast.success(result.message);
                    setResendActivationCode({status: false, email: ''});
                    setLoader(false);
                } else {
                    toast.error(result.message);
                    setLoader(false);
                }
            },
            (error) => {
                toast.error('Something went wrong, please try again.');
                setLoader(false);
            }
        )
    }



    /*------------------------------------------------------------------------------------
    // Settings Change Password
    ------------------------------------------------------------------------------------*/
    function authChangePassword(data) {
		setLoader(true);

        fetch(`${API_URL}/change-password`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
            body: JSON.stringify(data),
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    toast.success(result.message);
                    dispatch(removeCurrentUser());
                    setLoader(false);
                } else {
                    toast.error(result.message);
                    setLoader(false);
                }
            },
            (error) => {
                toast.error('Something went wrong, please try again.');
                setLoader(false);
            }
        )
    }



    /*------------------------------------------------------------------------------------
    // Token Renew
    ------------------------------------------------------------------------------------*/
    function authTokenRefresh(token) {
        fetch(`${API_URL}/refresh`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token)
            return response.json()
        })
        .then( (result) => {
                if ( result.status === 'success' ) 
                {
                    dispatch(setCurrentUser(result));
                } else {
                    toast.error(result.message);
                }
        		setLoader(false);
            },
            (err) => {
                setLoader(false);
                toast.error('Something went wrong, please try again.');
            }
        )
    }



  const value = { authLogin, authRegister, authLogout, authForgotPassword, accountActivation, authResetPassword, resendActivationCode, setResendActivationCode, authResendActivationCode, authCheck, authChangePassword, authTokenRefresh };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
