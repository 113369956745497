import './monitoring-wrap.style.scss';
import MonitoringPie from '../monitoring-pie/monitoring-pie.component';
import MonitoringStats from '../monitoring-stats/monitoring-stats.component';
import { useContext, useEffect } from 'react';
import { EntriesContext } from '../../context/entries.context';
import moment from 'moment';
import { ReactComponent as Harmless } from '../../assets/images/harmless.svg';
import { ReactComponent as Suspicious } from '../../assets/images/suspicious.svg';
import { ReactComponent as Malicious } from '../../assets/images/malicious.svg';
import { ReactComponent as Undetected } from '../../assets/images/undetected.svg';
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";
import { useNavigate } from 'react-router-dom';

const MonitoringWrap = () => {

	const { currentEntry, setCurrentEntry } = useContext(EntriesContext);

	const navigate = useNavigate()
	useEffect( () => {
		if ( !currentEntry.data.stats )
		{
			setCurrentEntry([])
			navigate('/dashboard')
		}
	}, [currentEntry])

	// date format 
	const { updated_at } = currentEntry;
	var updatedAt = moment(updated_at).format();
	const dateTime = moment(updatedAt).format("D MMMM, YYYY") + ' ' + moment(updatedAt).format("h:mm A");
	
	return (
		<div className='monitoring-stats'>
			<div className='wrapper'>
				<div className="row align-items-center">
					<div className="col-sm-12 col-md-6 col-lg-4">
						{ currentEntry?.data?.stats?.harmless ? <MonitoringPie harmless={currentEntry?.data?.stats?.harmless} undetected={currentEntry?.data?.stats?.undetected} malicious={currentEntry?.data?.stats?.malicious} suspicious={currentEntry?.data?.stats?.suspicious} /> : '' }
					</div>
					<div className="col-sm-12 col-md-6 col-lg-8">
						<div className="row info">
							<MonitoringStats title="Location" data={currentEntry?.entry} />
							<MonitoringStats title="URL Status" stats={currentEntry?.stats} />
							<MonitoringStats title="Updated" data={dateTime} />
							<MonitoringStats title="Status" data="200" className='badge green' />
						</div>
					</div>

					<div className='col-sm-12 mt-5'>
						<div className='tab'>
							<TabsComponent>
								<TabList>
									<Tab key='detection'>Detection</Tab>
									<Tab key='whois'>WhoIs</Tab>
								</TabList>
								
								<TabPanel key='detection' className="tests">
								{
									currentEntry.length !== 0 && Object.keys(currentEntry?.data).map((key, index) => (
										key === 'stats' || key === 'whois' ? '' :
										<div key={index} className={`single-test customTooltip ${ (currentEntry?.data[key]) === 'unrated' ? 'uUndetected' : (currentEntry?.data[key] === 'safe' || currentEntry?.data[key] === 'clean')  ? 'harmless' : ( currentEntry?.data[key] === 'phishing' || currentEntry?.data[key] === 'malicious' ? 'malicious' : 'suspicious' ) }`} data-name={currentEntry?.data[key]}>
											<p>	
												{ key }
												<span>{ (currentEntry?.data[key]) === 'unrated' ? <Undetected /> : (currentEntry?.data[key] === 'safe' || currentEntry?.data[key] === 'clean')  ? <Harmless /> : ( currentEntry?.data[key] === 'phishing' || currentEntry?.data[key] === 'malicious' ? <Malicious /> : <Suspicious /> ) }</span>
											</p>
										</div>
									))
								}
								</TabPanel>

								<TabPanel key='whois' className="whois">
								{ currentEntry?.data?.whois ? (
									<textarea disabled value={currentEntry?.data?.whois} />
								) : <p className='text-center'>No information</p>}

								</TabPanel>
								
							</TabsComponent>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MonitoringWrap;