// Importing Context
import { useContext, useRef, useState } from 'react';

// Importing usable components
import Button from '../button/button.component';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setToggleNewEntry } from '../../store/entries/entries.action';

// Importing icons
import {ReactComponent as CloseIcon} from '../../assets/images/close.svg';
import {ReactComponent as CheckIcon} from '../../assets/images/check.svg';

// Textarea Autosize
import TextareaAutosize from 'react-textarea-autosize';

// Validator
import validator from 'validator';

// Toastify
import { toast } from "react-toastify";

// Importing style for entries
import './entries.style.scss';
import { EntriesContext } from '../../context/entries.context';
import { useEffect } from 'react';
import { LoaderContext } from '../../context/loader.context';
import Loader from '../loader/loader.component';

const NewEntry = () => {

	// Context
	const { createNewEntry } = useContext(EntriesContext);
	const { loader, setLoader } = useContext(LoaderContext);
	const { addedEntryLoader, setAddedEntryLoader } = useContext(LoaderContext);

	const toggleNewEntry = useSelector((state) => state.entries.toggleNewEntry);

	const newEntryInput = useRef();

	// State
	const [entryInputValue, setEntryInputValue] = useState('');

	// Redux
	const dispatch = useDispatch();


	// set focus when new entry appear
	useEffect(() => {
		toggleNewEntry && newEntryInput.current.focus();
	}, [toggleNewEntry])



	// Hide input for new entry
	const discardNewEntry = () => {
		dispatch(setToggleNewEntry(false));
	}

	// handle Enter and Escape when new entry input is oppened
	const handleKeyPress = (event) => {
		// hit enter
		if (event.key === 'Enter' && event.shiftKey)
		{
		}
		else if (event.charCode === 13) {
			event.preventDefault();
			
			storeNewEntry();
		}
		// hit esc
		else if (event.key === 'Escape') 
			dispatch(setToggleNewEntry(false));
	}


	// set value of entry input
	const handleChange = (event) => {
		setEntryInputValue(event.target.value);
	}

	
	// adding new entry
	const storeNewEntry = () => {
		var addedEntries = entryInputValue.split(/\n/);

		if ( entryInputValue.length > 3 ) 
		{
			addedEntries.map((singleAddedEntry) => {
				
				var filteredURL = filterURL(singleAddedEntry);

				if (filteredURL)
				{			
					createNewEntry(filteredURL);
					setEntryInputValue('');
				}
				else {
					toast.error(`${singleAddedEntry} is not valid`)
				}
			})
		}
	}

	const filterURL = (url) => {

		if ( validator.isURL(url) || validator.isIP(url) )
		{
			if (url.substring(0, 8) === 'https://')
				url = url.substring(8, url.length);

			if (url.substring(0, 7) === 'http://')
				url = url.substring(7, url.length);

			if (url.substring(0, 4) === 'www.')
				url = url.substring(4, url.length);
				
			if (url.charAt(url.length-1) === '/')
				url = url.substring(0, url.length-1);
				
			// if ( url.includes('/') )
		} else {
			return false;
		}

		return url;
	}

	useEffect(() => {
		setTimeout(() => {
			setAddedEntryLoader(false)
		}, 1000)
	}, [addedEntryLoader]);
	
	// HTML
	return (
		<div className='tr new-entry mt-2'>
			<div className='location'>
				<div className="input-wrap">
					<TextareaAutosize rows={1} placeholder='URL/IP Address' type="url" ref={newEntryInput} disabled={!toggleNewEntry} onChange={handleChange} onKeyPress={handleKeyPress} onKeyDown={handleKeyPress} value={entryInputValue}></TextareaAutosize>
				</div>
			</div>

			{ 
				loader && !addedEntryLoader &&
				<div className='new-entry-loading'>
					<Loader />
				</div> 
			}

			{
				addedEntryLoader && 
				<div className='new-entry-added'>
					<CheckIcon />
				</div> 
			}

			{ 
				!loader && !addedEntryLoader &&
				<>
					<div className='new text-right'>
						<Button buttonType='new-entry' onClick={storeNewEntry} disabled={!toggleNewEntry}>
							<CheckIcon />
						</Button>
					</div>
					<div className='new text-right'>
						<Button buttonType='discard-new-entry' onClick={discardNewEntry}>
							<CloseIcon />
						</Button>
					</div>
				</> 
			}
		</div>
	);
}

export default NewEntry;