import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Input from "../../../components/input/input.component";
import Button from "../../../components/button/button.component";
import Loader from "../../../components/loader/loader.component";

import { AuthContext } from "../../../context/auth.context";
import { LoaderContext } from "../../../context/loader.context";


// default inputs login/pass value
const defaultFormFields = {
	email: '',
	password: '',
}

const Login = () => {

	// context
	const { authLogin, resendActivationCode, authResendActivationCode } = useContext(AuthContext);
	const { loader } = useContext(LoaderContext);


	// state
	const [ formFields, setFormFields ] = useState(defaultFormFields);


	// state for handling input change
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormFields({ ...formFields, [name]: value });
	};


	// handle login
	const handleLogin = async (e) => {
		e.preventDefault();
		
		// fn for making API Request
		authLogin(formFields.email, formFields.password);
	}


	// HTML
	return (
		<div className={`login ${ loader ? `loading` : ``}`}>
			<h3 className="auth-title">Log in</h3>
			<form onSubmit={handleLogin} className={loader ? 'disable' : ''}>
				{ loader && <Loader className='absolute' />}

				<Input onChange={handleChange} label='Email Address' required type="email" name="email" value={formFields.email} wrapClass={ resendActivationCode.status ? 'mb-2' : ''} />
				{ resendActivationCode.status ? <a href="#" onClick={() => authResendActivationCode()} className="d-block mb-3">Resend activation code?</a> : '' }
				<Input onChange={handleChange} showPassword={true} label='Password' required type="password" name="password" value={formFields.password} />
				<div className="input-wrap d-flex justify-content-between">
					<Link to='/forgot-password'>Forgot Password?</Link>
				</div>
				<div className="d-flex justify-content-between">
					<Button disabled={loader} buttonType="blue" type="submit">Log in</Button>
					<Link className='button outline' disabled={loader} to={loader ? '#' : '/register'}>Register</Link>
				</div>
			</form>
		</div>
	);
}

export default Login;