import './dashboard.style.scss';

import Header from "../../components/header/header.component";
import Stats from "../../components/stats/stats.component";
import Entries from "../../components/entries/entries.component";
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setStats, setToggleNewEntry } from '../../store/entries/entries.action';
import { EntriesContext } from '../../context/entries.context';


const Dashboard = () => {

	const entries = useSelector((state) => state.entries.entries);
	const stats = useSelector((state) => state.entries.stats);
	const dispatch = useDispatch();

	useEffect(()=>{
		window.scrollTo(0, 0);
		dispatch(setToggleNewEntry(false));
	}, [dispatch]);

	
	/**************************************************************************/
	// Handle check all checkbox
	/**************************************************************************/
	useEffect( () => {
		document.addEventListener("keydown", handleKeyDown);
	}, []);


	useEffect(() => {
		if (entries.length)
		{
			var harmless = 0;
			var suspicious = 0;
			var malicious = 0;
			var undetected = 0;
			entries.map((entry) => {
				harmless = (entry?.data?.stats?.status === 'harmless') ? harmless+1 : harmless;
				suspicious = (entry?.data?.stats?.status === 'suspicious') ? suspicious+1 : suspicious;
				malicious = (entry?.data?.stats?.status === 'malicious') ? malicious+1 : malicious;
				undetected = (entry?.data?.stats?.status === 'undetected' || entry.data.stats === undefined ) ? undetected+1 : undetected;
			})
			const stats = {
				harmless: harmless,
				suspicious: suspicious,
				malicious: malicious,
				undetected: undetected,
			}
			dispatch(setStats(stats))
		}
	}, [entries])


	const handleKeyDown = (e) => {
		if ( e.metaKey && e.which === 75 ) {
			dispatch(setToggleNewEntry(true));
		}
	}

	return (
		<div className="dashboard" onKeyDown={handleKeyDown}>
			<Header title='Dashboard' subtitle='Overview' />
			<Stats stats={stats} /> 
			<Entries onKeyDown={handleKeyDown} /> 
		</div>
	);
}

export default Dashboard;