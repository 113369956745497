import { Link } from 'react-router-dom';

// Import style for navigation
import './header.style.scss';

// Import Use & User context
import { useDispatch, useSelector } from "react-redux";
import { setToggleNewEntry } from '../../store/entries/entries.action';

// Import components
import Button from '../button/button.component';
import { Fragment, useContext } from 'react';
import { AuthenticatedContext } from '../../context/authenticated.context';


const Header = ({title, subtitle, newEntry = true}) => {

	const toggleNewEntry = useSelector((state) => state.entries.toggleNewEntry);

	// const { colorMode } = useContext(ColorModeContext);
	const dispatch = useDispatch();

	const handleNewEntry = () => {
		window.scrollTo({
			top: 200, 
      		behavior: 'smooth'
		});
		dispatch(setToggleNewEntry(true));
	}

	return (
		<header className="header">
			<div className="wrapper">
				<div className="row align-items-center">
					<div className="col-6 col-sm-6 col-md-6 col-lg-6 text-left">
						<p>{subtitle}</p>
						<h3>{title}</h3>
					</div>
					<div className="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
					<div className="d-flex justify-content-end align-items-center text-right">
						
						{ newEntry ? 
							!toggleNewEntry && (<Fragment>
								<Button buttonType='blue' onClick={handleNewEntry}>New Entry</Button>
							</Fragment>)
						: <Link to='/dashboard' className='button blue'>GO BACK</Link> }
					</div>
					</div>
				</div>
			</div>
		</header>
	); 
}

export default Header;