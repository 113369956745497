import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewEntry, removeEntry, addNewEntryInformation, setEntriesPagination, setEntries, setToggleNewEntry } from "../store/entries/entries.action";
import { LoaderContext } from "./loader.context";
import { useNavigate } from "react-router-dom";

import { API_URL } from '../conf';
import { toast } from "react-toastify";
import { AuthContext } from "./auth.context";
import moment from "moment";

export const EntriesContext = createContext({
    currentPage: 1,
    setCurrentPage: () => {},
    currentEntry: {},
    setCurrentEntry: () => {},
	checkedEntries: {},
    setCheckedEntries: () => {},
	filteredEntries: {},
    setFilteredEntries: () => {},
    searchedEntries: {},
    setSearchedEntries: () => {},
    boxFilteredEntries: {},
    setBoxFilteredEntries: () => {},
    addressType: {},
    setAddressType: () => {},
});

export const EntriesProvider = ({ children }) => {
	// State
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ currentEntry, setCurrentEntry ] = useState([]);
    const [ checkedEntries, setCheckedEntries ] = useState([]);
    const [ filteredEntries, setFilteredEntries ] = useState([]);
    const [ searchedEntries, setSearchedEntries ] = useState([]);
    const [ boxFilteredEntries, setBoxFilteredEntries ] = useState([]);
    const [ addressType, setAddressType ] = useState('all');
	
	// Context
	const { setLoader, setScreenLoader, setAddedEntryLoader, setFetchingEntriesLoader, setRecheckEntryLoader } = useContext(LoaderContext);
    const { authCheck, authTokenRefresh } = useContext(AuthContext);
	
	// Redux
	const token = useSelector((state) => state.user.currentUser.token);
	const dispatch = useDispatch();

    const navigate = useNavigate();


	/*------------------------------------------------------------------------------------
    // Fetch User Token after login
    ------------------------------------------------------------------------------------*/
    useEffect(() => {
        if ( token && token !== null && token !== undefined )
            fetchUserEntries();
    }, [token]);



    function checkTokenExpiration() {
        const token_expiration = JSON.parse(localStorage.getItem('token_expiration'));

        if ( token_expiration.check_expire < moment().unix() && moment().unix() <  token_expiration.expire_in  )
        {
            authTokenRefresh(token)
        }
    }



	/*------------------------------------------------------------------------------------
    // Fetch user entries
    ------------------------------------------------------------------------------------*/
    function fetchUserEntries() {
        checkTokenExpiration();
		setFetchingEntriesLoader(true);
        
		fetch(`${API_URL}/entries`, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    if  ( result.entries.length ) {
                        dispatch(setEntries(result.entries));
                        dispatch(setEntriesPagination({
                            current_page: result.page,
                            next_page: result.page+1,
                            last_page: result.total_pages,
                            per_page: result.entries_per_page
                        }));
                        setFetchingEntriesLoader(false);
                    } else {
                        dispatch(setToggleNewEntry(true));
                        setFetchingEntriesLoader(false);
                    }
                }
            },
            (error) => {
        		setFetchingEntriesLoader(false);
            }
        )
    }


	/*------------------------------------------------------------------------------------
    // New User Entry
    ------------------------------------------------------------------------------------*/
    function createNewEntry(entryURL) {
		setLoader(true);

		fetch(`${API_URL}/entries/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
			body:JSON.stringify({
				entry: entryURL,
			})
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
					dispatch(addNewEntry(result.record, true));
                    // toast.success(result.message)
                    setAddedEntryLoader(true);
                } else
                    toast.error(result.message);

        		setLoader(false);
            },
            (error) => {
                toast.error('Error while adding new entry. Please try again.')
                setLoader(false);
            }
        )
	}


	/*------------------------------------------------------------------------------------
    // Delete User Entry
    ------------------------------------------------------------------------------------*/
    function deleteEntry(uuid) {
		setLoader(true);
		
		fetch(`${API_URL}/entries/${uuid}`, {
            method: "DELETE", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
                    dispatch(removeEntry(uuid));
                    setLoader(false);
                } else {
                    setLoader(false);
                    toast.error(result.message)
                }
            },
            (error) => {
                toast.error('Error while deleting entry. Please try again.')
        		setLoader(false);
            }
        )
        
	}



    /*------------------------------------------------------------------------------------
    // Delete User Entry
    ------------------------------------------------------------------------------------*/
    function singleEntry( uuid ) {
        setScreenLoader(true);
		
		fetch(`${API_URL}/entries/${uuid}`, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status && result.status === 'success' ) 
                {
					dispatch(addNewEntryInformation(result.entry));
                    setCurrentEntry(result.entry);
                } else {
                    toast.error('Entry not found');
                    navigate('/dashboard');
                }
            setScreenLoader(false);
        },
        (error) => {
                toast.error('Entry not found')
                setScreenLoader(false);
                navigate('/dashboard');
            }
        )
	}


    /*------------------------------------------------------------------------------------
    // This function is same as singleEnytry except it's setting entry new infromation in reducer
    ------------------------------------------------------------------------------------*/
    function quickEntryRecheck( uuid ) {
		fetch(`${API_URL}/entries/${uuid}`, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
					dispatch(addNewEntry(result.entry));
                }
            },
        )
	}



    /*------------------------------------------------------------------------------------
    // Recheck single entry
    ------------------------------------------------------------------------------------*/
    function recheckEntry(uuid) {
        setRecheckEntryLoader(uuid);
		
		fetch(`${API_URL}/entries/recheck/${uuid}`, {
            method: "GET", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => {
            if ( response.status === 401 )
                authCheck(token);
            else return response.json()
        })
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
					dispatch(addNewEntry(result.record, true));
                    setRecheckEntryLoader(false);
                } else
                    toast.error(result.message);

                setRecheckEntryLoader(false);
            },
            (error) => {
                toast.error('Error while adding new entry. Please try again.')
                setRecheckEntryLoader(false);
            }
        )
	}



	const value = { fetchUserEntries, checkedEntries, setCheckedEntries, createNewEntry, deleteEntry, singleEntry, currentEntry, setCurrentEntry, filteredEntries, setFilteredEntries, searchedEntries, setSearchedEntries, boxFilteredEntries, setBoxFilteredEntries, addressType, setAddressType, recheckEntry, quickEntryRecheck, currentPage, setCurrentPage };
	return <EntriesContext.Provider value={value}>{children}</EntriesContext.Provider>;
};
