import './monitoring-pie.style.scss';
import ReactApexChart from "react-apexcharts";
import { useContext } from 'react';
import { ColorModeContext } from '../../context/color-mode.context';

const MonitoringPie = ({harmless, suspicious, malicious, undetected}) => {

	const { colorMode } = useContext(ColorModeContext);

	const trackBg = (colorMode === 'dark') ? '#061425' : '#EFF2F7';
	const textColor = (colorMode === 'dark') ? '#fff' : '#061425';

	const chart = {
		series: [malicious, suspicious, harmless, undetected],
		options: {
			colors:['#E63756', '#F5803D', '#30D27A', '#AAB8C9'],
			chart: {
				type: 'radialBar',
			},
			plotOptions: {
				radialBar: {
					dataLabels: {
						name: {
							fontSize: '15px',
							offsetY: -3,
						},
						value: {
							fontSize: '20px',
							offsetY: 5,
							color: textColor,
							fontWeight: 'bold',
							formatter: function(val) 
							{
								return parseInt(val);
							},
						},
					},
					
					startAngle: 0,
                  	endAngle: 360,

					track: {
						background: trackBg,
						strokeWidth: '100%',
						margin: 4, // margin is in pixels
						padding: 0,
					},
				},
			},
			stroke: {
				lineCap: 'round'
			},
			labels: ['Malicious', 'Suspicious', 'Harmless', 'Undetected'],
			grid: {
				show: true,
				borderColor: '#90A4AE',
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0
				},
			}

		}  
	};

	return (
		<div className='monitoring-pie'>
			<div className="pie">
				<ReactApexChart options={chart.options} series={chart.series} type="radialBar" height={230} />
			</div>
			<div className="pie-info">
				<p><span className='green'></span> Harmless</p>
				<p><span className='grey'></span> Undetected</p>
				<p><span className='orange'></span> Suspicious</p>
				<p><span className='red'></span> Malicious</p>
			</div>
		</div>
	);
};

export default MonitoringPie;