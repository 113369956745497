import { Link, useParams } from "react-router-dom";
import Input from "../../../components/input/input.component";
import Button from "../../../components/button/button.component";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth.context";
import { useState } from "react";
import { LoaderContext } from "../../../context/loader.context";
import Loader from "../../../components/loader/loader.component";

const ResetPassword = () => {

	// Use Params to get the token
	const { token } = useParams();
	
	// Context
	const { authResetPassword } = useContext(AuthContext);
	const { loader } = useContext(LoaderContext);

	// State
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');


	const handleResetPassword = (e) => {
		e.preventDefault();
		
		authResetPassword(token, password, confirmPassword);
	}

	return (
		<div className={`forgot-password ${ loader ? `loading` : ``}`}>
			<h3 className="auth-title">Reset Password</h3>

			<form onSubmit={handleResetPassword} className={loader ? 'disable' : ''}>
				{ loader && <Loader className='absolute' />}

				<Input label='New Password' required type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
				<Input label='Confirm New Password' required type="password" name="confirm_password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
				<div className="d-flex justify-content-between">
					<Button buttonType="blue" type="submit">Reset Password</Button>
					<Link className="button outline" to="/login">Login</Link>
				</div>
			</form>
		</div>
	);
}

export default ResetPassword;