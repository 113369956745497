import './monitoring.style.scss';
import Header from "../../components/header/header.component";
import MonitoringWrap from '../../components/monitoring-wrap/monitoring-wrap.component';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { EntriesContext } from '../../context/entries.context';
import { Helmet } from 'react-helmet';

const Monitoring = () => {

	// Context
	const { singleEntry, currentEntry, setCurrentEntry } = useContext(EntriesContext);


	// Get UUID Parameter from url
	const { uuid } = useParams();

	// Redux
	const {entriesInformation} = useSelector((state) => state.entries);

	// useEffect and check if entrie exists
	useEffect(() => {
		if (!entriesInformation[uuid])
		{
			singleEntry(uuid);
		} else {
			setCurrentEntry(entriesInformation[uuid]);
		}
	}, [entriesInformation]);

	return (
		<div className="monitoring">
			<Helmet>
				<title>{`Monitoring - ${currentEntry.entry} | Heimdall - Protector of your network realm`}</title>
				<meta property="og:title" content={`Monitoring - ${currentEntry.url} | Heimdall - Protector of your network realm`} />
			</Helmet>
			<Header title='Monitoring' subtitle='Overview' newEntry={false} />
			{ currentEntry.length !== 0 && <MonitoringWrap /> } 
		</div>
	);
}

export default Monitoring;