import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './store/store';

import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './bootstrap.scss';
import './index.scss';

import { ColorModeProvider } from './context/color-mode.context';
import { AuthProvider } from './context/auth.context';
import { FlashMessageProvider } from './context/flash-message.context';
import { EntriesProvider } from './context/entries.context';
import { LoaderProvider } from './context/loader.context';
import { AuthenticatedProvider } from './context/authenticated.context';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SettingsProvider } from './context/settings.context';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <AuthenticatedProvider>
                <LoaderProvider>
                    <FlashMessageProvider>
                        <AuthProvider>
                            <SettingsProvider>
                                <EntriesProvider>
                                    <ColorModeProvider>
                                        <App />
                                        <ToastContainer position="bottom-right" autoClose={2000} />
                                    </ColorModeProvider>
                                </EntriesProvider>
                            </SettingsProvider>
                        </AuthProvider>
                    </FlashMessageProvider>
                </LoaderProvider>
            </AuthenticatedProvider>
        </Provider>
    </BrowserRouter>
);
