// Importing Components
import Box from '../box/box.component';

// Importing Stats style
import './stats.style.scss';

// Importing svg icons 
import { ReactComponent as Harmless } from '../../assets/images/harmless.svg';
import { ReactComponent as Suspicious } from '../../assets/images/suspicious.svg';
import { ReactComponent as Malicious } from '../../assets/images/malicious.svg';
import { ReactComponent as Undetected } from '../../assets/images/undetected.svg';
import { useContext, useEffect, useState } from 'react';
import { EntriesContext } from '../../context/entries.context';
import { useSelector } from 'react-redux';
import { LoaderContext } from '../../context/loader.context';

import validator from 'validator';


// Redux


const Stats = ({stats}) => {
	
	// Context
	const entries = useSelector((state) => state.entries.entries);

	const { setCurrentPage, setFilteredEntries, setSearchedEntries, setBoxFilteredEntries, addressType } = useContext(EntriesContext);
	const { boxActive, setBoxActive } = useContext(LoaderContext);
	const [ tempFilteredEntries, setTempFilteredEntries] = useState([]);
	
	const handleClick = (filter) => {
		setCurrentPage(1);
		switch (filter) {
			case 'harmless':
				if (boxActive === 'harmless') {
					setBoxActive('')
					setFilteredEntries(entries)
					setSearchedEntries(entries)
					setBoxFilteredEntries(entries)
				 } else {
					setBoxActive('harmless')
				 }
				break;

			case 'suspicious':
				if (boxActive === 'suspicious') {
					setBoxActive('')
					setFilteredEntries(entries)
					setSearchedEntries(entries)
					setBoxFilteredEntries(entries)
				 } else {
					setBoxActive('suspicious')
				 }
				break;
			
			case 'malicious':
				if (boxActive === 'malicious') {
					setBoxActive('')
					setFilteredEntries(entries)
					setSearchedEntries(entries)
					setBoxFilteredEntries(entries)
				 } else {
					setBoxActive('malicious')
				 }
				break;

			case 'undetected':
				if (boxActive === 'undetected') {
					setBoxActive('')
					setFilteredEntries(entries)
					setSearchedEntries(entries)
					setBoxFilteredEntries(entries)
				 } else {
					setBoxActive('undetected')
				 }
				break;
		
			default:
				break;
		}
	}

	useEffect(() => {
		switch ( boxActive )
		{
			case 'harmless':
				entries.length && setTempFilteredEntries(entries.filter(entry => {
					if ( addressType === 'url' )
						return entry?.data?.stats?.status.toLowerCase() === 'harmless' && validator.isURL(entry.entry) && !validator.isIP(entry.entry);
					else if ( addressType === 'ip' )
						return entry?.data?.stats?.status.toLowerCase() === 'harmless' && validator.isIP(entry.entry);
					else 
						return entry?.data?.stats?.status.toLowerCase() === 'harmless';
				}))
				entries.length && setBoxFilteredEntries(entries.filter(entry => 
					entry?.data?.stats?.status.toLowerCase() === 'harmless'
				))
				break;
			case 'suspicious':
				entries.length && setTempFilteredEntries(entries.filter(entry => {
					if ( addressType === 'url' )
						return entry?.data?.stats?.status.toLowerCase() === 'suspicious' && validator.isURL(entry.entry) && !validator.isIP(entry.entry);
					else if ( addressType === 'ip' )
						return entry?.data?.stats?.status.toLowerCase() === 'suspicious' && validator.isIP(entry.entry);
					else 
						return entry?.data?.stats?.status.toLowerCase() === 'suspicious';
				}))
				entries.length && setBoxFilteredEntries(entries.filter(entry => 
					entry?.data?.stats?.status.toLowerCase() === 'suspicious'
				))
				break;
			case 'malicious':
				entries.length && setTempFilteredEntries(entries.filter(entry => {
					if ( addressType === 'url' )
						return entry?.data?.stats?.status.toLowerCase() === 'malicious' && validator.isURL(entry.entry) && !validator.isIP(entry.entry);
					else if ( addressType === 'ip' )
						return entry?.data?.stats?.status.toLowerCase() === 'malicious' && validator.isIP(entry.entry);
					else 
						return entry?.data?.stats?.status.toLowerCase() === 'malicious';
				}))
				entries.length && setBoxFilteredEntries(entries.filter(entry => 
					entry?.data?.stats?.status.toLowerCase() === 'malicious'
				))
				break;
			case 'undetected':
				entries.length && setTempFilteredEntries(entries.filter(entry => {
					if ( addressType === 'url' )
						return (entry?.data?.stats?.status.toLowerCase() === 'undetected' || entry?.data?.stats === undefined) && validator.isURL(entry.entry) && !validator.isIP(entry.entry);
					else if ( addressType === 'ip' )
						return (entry?.data?.stats?.status.toLowerCase() === 'undetected' || entry?.data?.stats === undefined) && validator.isIP(entry.entry);
					else 
						return (entry?.data?.stats?.status.toLowerCase() === 'undetected' || entry?.data?.stats === undefined);
				}))
				
				entries.length && setBoxFilteredEntries(entries.filter(entry => 
					entry?.data?.stats?.status.toLowerCase() === 'undetected'
				))
				
				break;
			default:
				entries.length && setTempFilteredEntries(entries.filter(entry => {
					if ( addressType === 'url' )
						return validator.isURL(entry.entry) && !validator.isIP(entry.entry);
					else if ( addressType === 'ip' )
						return validator.isIP(entry.entry);
					else 
						return entry;
				}))
				
				break;
		}
	}, [boxActive])


	useEffect(() => {
		setFilteredEntries(tempFilteredEntries);
		setSearchedEntries(tempFilteredEntries)
	}, [tempFilteredEntries])
	


	return (
		<section className="stats">
			<div className="wrapper">
				<div className="boxes row">
					<div className={`col-6 col-sm-6 col-md-6 col-lg-3 mt-4 mt-lg-0 ${boxActive === 'harmless' ? 'active' : (boxActive === '') ? 'active' : 'opacity'}`} onClick={() => handleClick('harmless')}>
						<Box title="Harmless" icon={Harmless} number={stats.harmless} />
					</div>

					<div className={`col-6 col-sm-6 col-md-6 col-lg-3 mt-4 mt-lg-0 ${boxActive === 'suspicious' ? 'active' : (boxActive === '') ? 'active' : 'opacity'}`} onClick={() => handleClick('suspicious')}>
						<Box title="Suspicious" icon={Suspicious} number={stats.suspicious} />
					</div>

					<div className={`col-6 col-sm-6 col-md-6 col-lg-3 mt-4 mt-lg-0 ${boxActive === 'malicious' ? 'active' : (boxActive === '') ? 'active' : 'opacity'}`} onClick={() => handleClick('malicious')}>
						<Box title="Malicious" icon={Malicious} number={stats.malicious} />
					</div>

					<div className={`col-6 col-sm-6 col-md-6 col-lg-3 mt-4 mt-lg-0 ${boxActive === 'undetected' ? 'active' : (boxActive === '') ? 'active' : 'opacity'}`} onClick={() => handleClick('undetected')}>
						<Box title="Undetected" icon={Undetected} number={stats.undetected} />
					</div>
				</div>
			</div>
		</section>
	);
}

export default Stats;