import { Fragment, useContext, useState } from "react";
import { Outlet, Link } from "react-router-dom"

import { AuthContext } from '../../context/auth.context';
import { ColorModeContext } from "../../context/color-mode.context";

// Import style for navigation
import './navigation.style.scss';

// Import images and icons for navigation
import { ReactComponent as Logo } from '../../assets/images/logo-text.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as CogIcon } from '../../assets/images/cog.svg';
import { ReactComponent as MoonIcon } from '../../assets/images/moon.svg';
import { ReactComponent as SunIcon } from '../../assets/images/sun.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/logout.svg';
import { LoaderContext } from "../../context/loader.context";


const Navigation = () => {
	
	const {colorMode, setColorMode, colorModeIcon, setColorModeIcon} = useContext(ColorModeContext);
	const { authLogout } = useContext(AuthContext);
	const { screenLoader } = useContext(LoaderContext);
	const [ blueColor, setBlueColor ] = useState( colorMode === 'dark' ? '#07a4bf' : '#157FFF' );

	const handleLogout = () => {
		authLogout(localStorage.getItem('token'));
	}

	const handleColorMode = () => {
		if ( colorMode === 'dark' )
		{
			setColorMode('light');
			setColorModeIcon(<MoonIcon />);
			setBlueColor('#157FFF')
		} else {
			setColorMode('dark');
			setColorModeIcon(<SunIcon />);
			setBlueColor('#07a4bf')
		}
	}

	return (
		<Fragment>
			{ screenLoader ? (
				<div className="loading-screen">
					<Logo fill="transparent" stroke={blueColor} />
				</div>
			) : ''}

			<nav className="navigation">
				<div className="logo">
					<Link to='/dashboard'><Logo fill={blueColor} /></Link>
				</div>

				<div className="menu">
					<Link to='/dashboard'><HomeIcon stroke={(window.location.pathname === '/dashboard') ? blueColor : '#5f7084'} /></Link>
					<Link to='/settings'><CogIcon stroke={(window.location.pathname === '/settings') ? blueColor : '#5f7084'} /></Link>
					<Link to='#' onClick={handleColorMode}>{colorModeIcon}</Link>
					<Link to='#' onClick={handleLogout}><LogoutIcon /></Link>
				</div>
			</nav>
			
			<Outlet />
		</Fragment>
	);
}

export default Navigation;