// Importing Stats style
import { useContext, useState } from 'react';
import { AuthContext } from '../../context/auth.context';
import { LoaderContext } from '../../context/loader.context';
import Button from '../button/button.component';
import Input from '../input/input.component';
import Loader from '../loader/loader.component';
import './password-change.style.scss';


const PasswordChange = () => {

	const { loader } = useContext(LoaderContext);
	const { authChangePassword } = useContext(AuthContext);

	const [ data, setData ] = useState({
		old_password: '',
		password: '',
		password_confirmation: '',
	})


	const handleSubmit = (e) => {
		e.preventDefault();
		authChangePassword(data);
	}


	return (
		<section className={`login mt-5 ${ loader.main ? `loading` : ``}`}>
			<h5 className='subheading'>Settings</h5>

			<form action="" className={`${ loader.main ? `disable` : ``}`}>
				{ loader.main && <Loader className='absolute' />}
				<div className="row">
					<div className="col-lg-6">
						<Input label='Current Password' required type="password" name="old_password" onChange={(e) => setData({...data, old_password: e.target.value})} />
					</div>
					<div className="col-lg-6">
						<Input label='New Password' required type="password" name="password" onChange={(e) => setData({...data, password: e.target.value})} />
						<Input label='Confirm New Password' required type="password" name="password_confirmation" onChange={(e) => setData({...data, password_confirmation: e.target.value})} />
					</div>
				</div>

				<div className="text-right mt-4">
					<Button buttonType="dark" onClick={handleSubmit}>Change Password</Button>
				</div>
			</form>
		</section>
	);
}

export default PasswordChange;