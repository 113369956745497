// Importing Stats style
import { Fragment, useEffect, useState } from 'react';
import { useContext } from 'react';
import { SettingsContext } from '../../context/settings.context';
import Button from '../button/button.component';
import Input from '../input/input.component';
import './preferences.style.scss';
import Hooks from '../hooks/hooks.component';
import HookLogs from '../hooks/hook-logs.component';
import { CSSTransition } from 'react-transition-group';
import { LoaderContext } from '../../context/loader.context';


const Preferences = () => {

	const { setHooks, deleteHookPopup, setDeleteHookPopup, deleteHook } = useContext(SettingsContext);
	const { newHookLoader } = useContext(LoaderContext);
	const [ notificationType, setNotificationType ] = useState('email');
	const [ showConfirmPopup, setShowConfirmPopup ] = useState(false);
	const [ hookLocation, setHookLocation ] = useState({
		sms: '',
		email: '',
		webhook: '',
	})

	// Create New hook depending on case
	const handleSave = () => {
		switch (notificationType) {
			case 'email':
				setHooks(notificationType, hookLocation.email);
				break;

			case 'webhook':
				setHooks(notificationType, hookLocation.webhook);
				break;
		
			default:
				break;
		}
	}

	// Display popup when delete hook popup state is true
	useEffect(() => {
		deleteHookPopup && setShowConfirmPopup(true);
	}, [deleteHookPopup])
	

	// remove popup by default when switching locations
	useEffect(() => {
		setDeleteHookPopup(false);
		setShowConfirmPopup(false);
	}, [])

	
	
	// Delete Hook after popup confirm
	const handleDeleteHook = () => {
		deleteHook(deleteHookPopup)
		setShowConfirmPopup(false);
	}


	return (
		<Fragment>
			<section className="preferences">

				<div className="row">
					<div className="col-xs-12 col-sm-12 col-lg-6">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-lg-12">
								<h5 className='subheading'>New Hook</h5>
							</div>
							<div className="col-xs-12 col-sm-4 col-lg-4">
								<div className="input-wrap">
									<label htmlFor="">Notification Type</label>
									<select onChange={(e) => setNotificationType(e.target.value)}>
										<option value="email">Email</option>
										<option value="webhook">WebHook</option>
									</select>
								</div>
							</div>
							<div className="col-xs-12 col-sm-8 col-lg-8 mt-8">
								<div className="">
									{ 
										notificationType === 'email' ? <Input label='Email' onChange={(e) => setHookLocation({...hookLocation, email: e.target.value})} value={hookLocation.email} name="email" id="email" />
										: notificationType === 'webhook' ? <Input label='URL' onChange={(e) => setHookLocation({...hookLocation, webhook: e.target.value})} value={hookLocation.webhook} name="webhook" id="webhook" /> : ''
									}
								</div>
							</div>
							<div className="col-xs-12 col-sm-12 col-lg-12 text-left mt-4">
								<Button disabled={newHookLoader ? true : false} onClick={handleSave} buttonType='blue'>Add New Hook</Button>
							</div>
						</div>
					</div>
					
					<Hooks />
					
					<HookLogs />
				</div>
			</section>

			<CSSTransition in={showConfirmPopup} timeout={0} classNames="fade">
				<div className="popup" onClick={ () => {setShowConfirmPopup(false); setDeleteHookPopup(false)} }>
					<div className="popup-container text-center" onClick={e => e.stopPropagation()}>
						<h4>Confirm delete</h4>
						<p>Are you sure you are want to remove your hook?</p>

						<div className='mt-3'>
							<button className='button button-xs red mr-4' onClick={handleDeleteHook}>Yes</button>
							<button className='button button-xs blue' onClick={() => {setShowConfirmPopup(false); setDeleteHookPopup(false)}}>No</button>
						</div>
					</div>
				</div>
			</CSSTransition>

		</Fragment>
	);
}

export default Preferences;