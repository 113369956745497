import { API_URL } from '../conf';
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserHooks, setUserHookLogs } from '../store/user/user.action';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { LoaderContext } from './loader.context';


export const SettingsContext = createContext();

export const SettingsProvider = ({ children }) => {
    const [ deleteHookPopup, setDeleteHookPopup ] = useState(false);

    const { setNewHookLoader, loader } = useContext(LoaderContext);
	
	// Redux
    const dispatch = useDispatch();
	const token = useSelector((state) => state.user.currentUser.token);


	/*------------------------------------------------------------------------------------
    // Fetch User Token after login
    ------------------------------------------------------------------------------------*/
    useEffect(() => {
        if ( token && token !== null && token !== undefined )
        {
			getHooks()
            
			getHookLogs()
        }
    }, [token]);



	/*------------------------------------------------------------------------------------
    // Fetch user already added hooks if exist
    ------------------------------------------------------------------------------------*/
    function getHooks() 
	{
        setNewHookLoader(true)
		fetch(`${API_URL}/hooks`, {
            method: "GET", 
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => response.json() )
        .then( (result) => {
            if ( result.status === 'success' )
            {
                dispatch(setUserHooks(result.hooks));
                setNewHookLoader(false)
            } else
                setNewHookLoader(false)
            
        });
	}



    /*------------------------------------------------------------------------------------
    // Fetch user already added hooks if exist
    ------------------------------------------------------------------------------------*/
    function getHookLogs() 
	{
        setNewHookLoader(true)
		fetch(`${API_URL}/hooks/logs`, {
            method: "GET", 
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then((response) => response.json() )
        .then( (result) => {
            if ( result.status === 'success' )
            {
                dispatch(setUserHookLogs(result.hooks));
                setNewHookLoader(false)
            } else
                setNewHookLoader(false)
        });
	}


	/*------------------------------------------------------------------------------------
    // Set user Hooks
    ------------------------------------------------------------------------------------*/
	function setHooks(type, location) 
	{
        setNewHookLoader(true)
		let data = {
			type: type,
			location: location
		}

		fetch(`${API_URL}/hooks`, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+token,
            },
			body: JSON.stringify(data)
        })
        .then( (response) => response.json() )
        .then( (result) => {
            if ( result.status === 'success' )
            {
				toast.success(result.message);
                getHooks();
            } else {
                setNewHookLoader(false)
                toast.error(result.message);
            }
        });
	}


    /*------------------------------------------------------------------------------------
    // Delete user Hooks
    ------------------------------------------------------------------------------------*/
	function deleteHook(id) 
	{
        setNewHookLoader(true);
		fetch(`${API_URL}/hooks/${id}`, {
            method: "DELETE", 
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+token,
            },
        })
        .then( (response) => response.json() )
        .then( (result) => {
            if ( result.status === 'success' )
            {
				// toast.success(result.message);
                getHooks();
                setDeleteHookPopup(false);
            } else {
                setNewHookLoader(true);
				toast.error(result.message);
            }
        });
	}



    /*------------------------------------------------------------------------------------
    // Edit user Hooks
    ------------------------------------------------------------------------------------*/
	function editHook(id, location) 
	{
        setNewHookLoader(true);

        let data = {
            location: location
        }

		fetch(`${API_URL}/hooks/${id}`, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer "+token,
            },
            body: JSON.stringify(data)
        })
        .then( (response) => response.json() )
        .then( (result) => {
            if ( result.status === 'success' )
            {
				// toast.success(result.message);
                getHooks();
            } else 
				toast.error(result.message);
            
            setNewHookLoader(false);
        });
	}


	const value = { getHooks, setHooks, deleteHook, editHook, deleteHookPopup, setDeleteHookPopup };
	return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
};
