import { ReactComponent as Harmless } from '../../assets/images/harmless.svg';
import { ReactComponent as Suspicious } from '../../assets/images/suspicious.svg';
import { ReactComponent as Malicious } from '../../assets/images/malicious.svg';
import { ReactComponent as Undetected } from '../../assets/images/undetected.svg';

const MonitoringStats = (props) => {

	const {title, data, stats} = props;

	const icon = stats ? stats.malicious > 0 ? <Malicious /> : ( stats.malicious === 0 && stats.suspicious > 0 ? <Suspicious /> : ( stats.malicious === 0 && stats.suspicious === 0 && stats.harmless > 0 ? <Harmless /> : <Undetected /> ) ) : '';
	const text = stats ? stats.malicious > 0 ? 'Malicious' : ( stats.malicious === 0 && stats.suspicious > 0 ? 'Suspicious' : ( stats.malicious === 0 && stats.suspicious === 0 && stats.harmless > 0 ? 'Harmless' : 'Undetected' ) ) : '';

	return (
		<div className="col-6 col-sm-6 col-md-4 col-lg-4">
			<p className='faded'>{title}</p>
			<p {...props} style={{display: "inline-flex", alignItems: "center", gridGap: '10px'}}>{icon} { data ? data : text }</p>
		</div>
	);
};

export default MonitoringStats;