import { Link } from "react-router-dom";
import Input from "../../../components/input/input.component";
import Button from "../../../components/button/button.component";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth.context";
import { useState } from "react";
import { LoaderContext } from "../../../context/loader.context";
import Loader from "../../../components/loader/loader.component";

const ForgotPassword = () => {

	// Context
	const { authForgotPassword } = useContext(AuthContext);
	const { loader } = useContext(LoaderContext);

	// State
	const [emailInput, setEmailInput] = useState('');


	const handleInputChange = (e) => {
		setEmailInput(e.target.value);
	}


	const handleForgotPassword = (e) => {
		e.preventDefault();
		
		authForgotPassword(emailInput);
	}

	return (
		<div className={`forgot-password ${ loader ? `loading` : ``}`}>
			<h3 className="auth-title">Forgot Password</h3>

			<form onSubmit={handleForgotPassword} className={loader ? 'disable' : ''}>
				{ loader && <Loader className='absolute' />}
				<Input label='Email Address' required type="email" name="email" value={emailInput} onChange={handleInputChange} />
				<div className="d-flex justify-content-between">
					<Button buttonType="blue" type="submit">Reset Password</Button>
					<Link className="button outline" to="/login">Login</Link>
				</div>
			</form>
		</div>
	);
}

export default ForgotPassword;