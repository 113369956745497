import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const UnprotectedRoute = ({ children }) => {

    const token = useSelector((state) => state.user.currentUser.token);

	if ( token )
	  return <Navigate to="/dashboard" />;
  
	return children;
};

export default UnprotectedRoute;