import { useState, createContext } from "react";

export const AuthenticatedContext = createContext({
    authenticated: false,
    setAuthenticated: () => null,
});


export const AuthenticatedProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);  

	const value = { authenticated, setAuthenticated };
	return <AuthenticatedContext.Provider value={value}>{children}</AuthenticatedContext.Provider>;
};
