import { useState } from 'react';
import './input.style.scss';

import { ReactComponent as Show } from '../../assets/images/show-password.svg'
import { ReactComponent as Hide } from '../../assets/images/hide-password.svg'

const Input = ({ label, name, type, showPassword = '', wrapClass = false, ...otherProps }) => {

	const [ tmpType, setTmpType ] = useState(type);
	const [ typeIcon, setTypeIcon ] = useState(<Show stroke='#aebbcc' />);

	const handleShowPassword = () => {
		if (tmpType === 'password') 
		{
			setTmpType('text');
			setTypeIcon(<Hide stroke='#aebbcc' />)
		}
		else {
			setTmpType('password');
			setTypeIcon(<Show stroke='#aebbcc' />)
		}
	}

	return (
		<div className={`input-wrap ${wrapClass}`}>
			{
				label && (<label htmlFor={name}>{label}</label>)
			}
			{
				showPassword && <span className='show-password' onClick={handleShowPassword}>{typeIcon}</span>
			}
			<input name={name} id={name} type={tmpType} {...otherProps} />
		</div>
	);
}

export default Input;