import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { LoaderContext } from '../../context/loader.context';
import Loader from '../loader/loader.component';

import Hook from './hook.component';

const Hooks = () => {
	// Redux
	const hooks = useSelector( state => state.user.currentUser.settings.hooks );
	const { newHookLoader } = useContext(LoaderContext);

	return (
		<div className="col-xs-12 col-sm-12 col-lg-6 mt-lg-0 mt-sm-5 mt-5">
			<h5 className='subheading'>Your Hooks</h5>

			<div className="hooks">
				{ hooks.length !== 0 ? (
					<div>
						<div className="head">
							<p>Type</p>
							<p>Location</p>
							<div className="edit"></div>
							<div className="delete"></div>
						</div>
						<ul className="body">
							{
								hooks.map( hook => (
									<Hook key={hook.id} setting={hook} />
								))
							}
						</ul>
						{newHookLoader && <Loader /> }
					</div>
				) : <p className='text-center'>You don't have any hooks</p> }
			</div>
		</div>
	)
}

export default Hooks