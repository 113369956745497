import React from "react";

// importing style for box
import './box.style.scss';

const Box = ({title, number, icon}) => {
	return (
		<div className="box">
			<div className="box-information">
				<p className="text-uppercase">{title}</p>
				<h3>{(number) ? number : 0}</h3>
			</div>
			<div className="box-icon">
				{React.createElement(icon)}
			</div>
		</div>
	);
}

export default Box;