import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const AccountActivation = () => {
	// Get UUID Parameter from url
	const navigate = useNavigate();
	const { activation_token } = useParams();

	const [animation, setAnimation] = useState('');

	useEffect(() => {
		let data = {
            token: activation_token,
        }

		fetch(`https://api.heimdall.id/verify-email`, {
            method: "POST", // or 'PUT'
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
        .then((response) => response.json())
        .then(
            (result) => {
                if ( result.status === 'success' ) 
                {
					setAnimation('activated');
					setTimeout(() => {
						navigate('/login');
					}, 2000);
                } else {
					setAnimation('rejected');
                }
            }
        )
	}, [])

	return(
		<div className="ForgotPassword loading">
			{ animation === '' ? 
				(<div><div className="loader"></div><p className="auth-title text-center mb-0">Activating account...</p></div>) 
			: animation === 'activated' ? 
				(<div>
					<div className="swal2-icon swal2-success swal2-animate-success-icon" style={{display: 'flex'}}>
						<div className="swal2-success-circular-line-left"></div>
						<span className="swal2-success-line-tip"></span>
						<span className="swal2-success-line-long"></span>
						<div className="swal2-success-ring"></div> 
						<div className="swal2-success-fix"></div>
						<div className="swal2-success-circular-line-right"></div>
					</div>
					<p className="font-bold text-center">Succesfully activated account</p>
				</div>) 
			: 
				(<div>
					<div className="swal2-icon swal2-error swal2-animate-error-icon" style={{display: 'flex'}}>
						<span className="swal2-x-mark">
							<span className="swal2-x-mark-line-left"></span>
							<span className="swal2-x-mark-line-right"></span>
						</span>
					</div>
					<p className="font-bold text-center">Activation key expired</p>
				</div>) 
			}
		</div>
	)
}

export default AccountActivation;