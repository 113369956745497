import { useState, createContext, useEffect } from "react";

// importing Icons for color mode
import { ReactComponent as MoonIcon } from '../assets/images/moon.svg';
import { ReactComponent as SunIcon } from '../assets/images/sun.svg';

// as the actual value you want to access
export const ColorModeContext = createContext({
	colorMode: (localStorage.getItem('colorMode')) ? localStorage.getItem('colorMode') : (window.matchMedia("(prefers-color-scheme: dark)").matches) ? 'dark' : 'light',
	setColorMode: () => null,
	colorModeIcon: <MoonIcon />,
	setColorModeIcon: () => null,
});

export const ColorModeProvider = ({ children }) => {
	const [colorMode, setColorMode] = useState( (localStorage.getItem('colorMode')) ? localStorage.getItem('colorMode') : (window.matchMedia("(prefers-color-scheme: dark)").matches) ? 'dark' : 'light');
	const [colorModeIcon, setColorModeIcon] = useState(getIcon(localStorage.getItem('colorMode') || 'light'));

	function getIcon (colorMode) {
		if ( colorMode === 'light' )
			return <MoonIcon />
		else 
			return <SunIcon />
	}
	
	const value = { colorMode, colorModeIcon, setColorMode, setColorModeIcon };

	useEffect( () => {
		localStorage.setItem('colorMode', colorMode);

		document.body.removeAttribute('class');
		document.body.classList.toggle(colorMode);
	}, [colorMode]);

	
	return <ColorModeContext.Provider value={value}>{children}</ColorModeContext.Provider>
}