import React, { useContext, useEffect, useRef, useState } from 'react'
import {ReactComponent as DeleteIcon} from '../../assets/images/delete.svg'
import {ReactComponent as EditIcon} from '../../assets/images/edit.svg'
import {ReactComponent as CheckIcon} from '../../assets/images/check.svg'
import {ReactComponent as CloseIcon} from '../../assets/images/close.svg'
import { SettingsContext } from '../../context/settings.context'
import { CSSTransition } from 'react-transition-group'
import moment from 'moment';

const Hook = ({setting}) => {

	// Use State
	const [ newLocation, setNewLocation ] = useState('');
	const [ editingHook, setEditingHook ] = useState(false);
	const [ hookDropdown, setHookDropdown ] = useState(false);

	// Ref
	const editLocationInput = useRef();

	// Context
	const { editHook, setDeleteHookPopup } = useContext(SettingsContext);

	// Handle user removing hook 
	const  handleDeleteHook = (id) => {
		setDeleteHookPopup(id);
	}

	const handleEditHook = (id, location) => {
		if ( newLocation !== setting.location )
		{
			editHook(id, location);
		}
		setEditingHook(false);
	}

	// Editing proccess handling
	const handleEditingHook = (id, location) => {
		setEditingHook(id);
		setNewLocation(location)
	}


	useEffect(() => {
		editingHook && editLocationInput.current.focus();
	}, [editingHook])
	


	return (
		<li className="hook">
			<p onClick={() => setting.failed.length > 0 ? setHookDropdown(!hookDropdown) : ''} className={`type ${setting.failed.length > 0 ? 'dropdown' : ''} ${setting.type} ${hookDropdown ? 'opened' : ''}`}><span>{setting.type} {setting.failed.length > 0 ? '('+setting.failed[0].attempts+')' : ''}</span></p>
			<div className='location'>
				<input type="text" ref={editLocationInput} className={`${editingHook === setting.id ? 'edit' : ''}`} onChange={(e) => setNewLocation(e.target.value)} value={editingHook === setting.id ? newLocation : setting.location} disabled={ editingHook === setting.id ? false : true} />
			</div>
			<div className="edit">
				{ editingHook === setting.id ? <CheckIcon stroke="rgb(95, 112, 132)" strokeWidth="2" onClick={() => {handleEditHook(setting.id, newLocation)}} /> : <EditIcon stroke="rgb(95, 112, 132)" strokeWidth="2" onClick={() => {handleEditingHook(setting.id, setting.location)}} /> }
			</div>
			<div className="delete">
				{ editingHook === setting.id ? <CloseIcon stroke="rgb(95, 112, 132)" strokeWidth="2" onClick={() => setEditingHook(false)} /> : <DeleteIcon onClick={() => handleDeleteHook(setting.id)} /> }
			</div>

			{ 
				setting.failed.length > 0 &&
				<CSSTransition in={hookDropdown} timeout={0} classNames="fade">
					<div className='hook-stats mt-3'>
						<div>
							<p className='subheading'>Failed attempts</p>
							<p>{setting.failed[0].attempts}</p>
						</div>
						<div>
							<p className='subheading text-right'>Next Check</p>
							<p>{moment(setting.failed[0].next_check*1000).format('MMM Do, h:mm')}</p>
						</div>
					</div>
				</CSSTransition>
			}
		</li>
	)
}

export default Hook