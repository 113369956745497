import moment from 'moment';
import { ACTION_TYPES } from './user.types';

const INITIAL_STATE = {
	currentUser: {
		token: localStorage.getItem('token') ?? false,
		email: localStorage.getItem('email') ?? false,
		isAuthenticated: localStorage.getItem('token') ?? false,
		settings: {
			hooks: [],
			logs: []
		},
	},
}

export const userReducer = (state = INITIAL_STATE, action) => {
	
	const { type, payload } = action;

	switch(type) {
		case ACTION_TYPES.SET_CURRENT_USER:
			// check if the response status is success, if it's success set localStorage for token and email
			if ( payload.status !== 'success' ) {
				return {
					...state,
				}
			}

			let token_expiration = {
				check_expire: moment().add(payload.expires_in * 0.9, 'seconds').unix(),
				expire_in: moment().add(payload.expires_in, 'seconds').unix(),
			}

			localStorage.setItem( 'token', payload.access_token);
			localStorage.setItem( 'token_expiration', JSON.stringify(token_expiration) );
			localStorage.setItem( 'email', payload.user.email);

			return {
				...state,
				currentUser: {
					token: payload.access_token,
					email: payload.user.email,
					isAuthenticated: payload.status === 'success' ? true : false,
				}
			}
		case ACTION_TYPES.SET_USER_HOOKS:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					settings: {
						...state.currentUser.settings,
						hooks: payload
					}
				}
			}
		case ACTION_TYPES.SET_USER_HOOK_LOGS:
			return {
				...state,
				currentUser: {
					...state.currentUser,
					settings: {
						...state.currentUser.settings,
						logs: payload
					}
				}
			}
		case ACTION_TYPES.REMOVE_CURRENT_USER:
			localStorage.removeItem( 'token' );
			localStorage.removeItem( 'email' );
			localStorage.removeItem( 'settings' );
			localStorage.removeItem( 'token_expiration' );
			return {
				...state,
				currentUser: {
					isAuthenticated: false,
					email: false,
					token: null,
					settings: {
						hooks: [],
						logs: []
					},
				}
			}
		default:
			return state;
	}
}
