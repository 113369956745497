import { useState } from 'react';
import { ReactComponent as ArrowDown } from '../../assets/images/angle-down.svg'
import moment from 'moment';

const HookLog = ({log, index}) => {
	const [ togleLogInformation, setTogleLogInformation ] = useState(false);
	
	return (
		<li className={`log ${togleLogInformation ? 'active' : ''}`} key={index} onClick={()=>setTogleLogInformation(!togleLogInformation)}>
			<div className="top-information">
				<div>{JSON.parse(log?.payload).payload?.entry.entry}</div>
				<div className='response'>
					<span className={`badge ${log?.code === 200 ? 'green' : 'orange'}`}>{log?.code}</span>
				</div>
				<div className="edit">
					{moment(log?.created_at).format("D-MM-YYYY HH:mm:ss")}
				</div>
				<div className="status">
					<span className={`badge ${(log?.status) ? 'green-fill' : 'red-fill'}`}>{(log?.status) ? 'Success' : 'Fail'}</span>
				</div>
				<div className="arrow">
					<ArrowDown />
				</div>
			</div>

			<div className={`log-information`} onClick={e => e.stopPropagation()}>
				<table>
					<tbody>
						<tr>
							<td align='right'>Body</td>
							<td className='body'>
								<code>
									{log?.body}
								</code>
							</td>
						</tr>
						<tr>
							<td align='right'>
								Payload
							</td>
							<td className='request'>
								<textarea disabled={true} value={ log?.payload ? JSON.stringify(JSON.parse(log?.payload), null, 4) : '' }>
									{ log?.payload ? JSON.stringify(JSON.parse(log?.payload), null, 4) : '' }
								</textarea>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</li> 
	)
}

export default HookLog