import { Link } from "react-router-dom";
import Input from "../../../components/input/input.component";
import Button from "../../../components/button/button.component";
import Loader from "../../../components/loader/loader.component";
import { useContext } from "react";
import { AuthContext } from "../../../context/auth.context";
import { useState } from "react";
import { LoaderContext } from "../../../context/loader.context";

// 
const defaultFormFields = {
	email: '',
	password: '',
	password_confirmation: '',
}

const Register = () => {
	const { authRegister } = useContext(AuthContext);
	const { loader } = useContext(LoaderContext);

	// state
	const [ formFields, setFormFields ] = useState(defaultFormFields);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormFields({ ...formFields, [name]: value });
	}
	
	const handleRegister = (e) => {
		e.preventDefault();
		
		// fn for making API Request
		authRegister(formFields.email, formFields.password, formFields.password_confirmation);
	}

	return (
		<div className="register">
			<h3 className="auth-title">Register</h3>

			<form onSubmit={handleRegister} className={loader ? 'disable' : ''}>
				{ loader && <Loader className='absolute' />}
				<Input label='Email Address' required type="email" name="email" onChange={handleChange} value={formFields.email} />
				<Input label='Password' showPassword={true} required type="password" name="password" onChange={handleChange} value={formFields.password} />
				<Input label='Confirm Password' showPassword={true} required type="password" name="password_confirmation" onChange={handleChange} value={formFields.password_confirmation} />
				<Button buttonType="blue" type="submit">Register</Button>
				<div className="mt-4">
					<p>Already have an account? <Link to='/login'>Log in</Link></p>
				</div>
			</form>
		</div>
	);
}

export default Register;