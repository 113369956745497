import { useState, createContext } from "react";


export const FlashMessageContext = createContext({
    flashMessage: {
        status: '',
        message: ''
    },
    setFlashMessage: () => null,
});


export const FlashMessageProvider = ({ children }) => {
    const [flashMessage, setFlashMessage] = useState([{ status: '', message: ''}]);

	const value = { flashMessage, setFlashMessage };
	return <FlashMessageContext.Provider value={value}>{children}</FlashMessageContext.Provider>;
};
