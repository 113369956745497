import './settings.style.scss';

import Header from "../../components/header/header.component";
import Preferences from "../../components/preferences/preferences.component";
import PasswordChange from "../../components/password-change/password-change.component";
import { Tab, Tabs as TabsComponent, TabList, TabPanel } from "react-tabs";


const Settings = () => {
	return (
		<div className="settings">
			<Header title='Settings' subtitle='Overview' newEntry={false} />
			<div className='wrapper mt-3'>
				<div className='tab'>
					<TabsComponent>
						<TabList>
							<Tab key='password'>Change Password</Tab>
							<Tab key='preferences'>Hooks</Tab>
						</TabList>
						
						<TabPanel key='password' className="password">
							<PasswordChange /> 
						</TabPanel>
						
						<TabPanel key='preferences' className="preferences">
							<Preferences /> 
						</TabPanel>
					</TabsComponent>
				</div>
			</div>
		</div>
	);
}

export default Settings;