import { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as LogoText } from '../../assets/images/logo-text.svg';
import { ReactComponent as Stats } from '../../assets/images/landing/stats.svg';
import { ReactComponent as ClientArea } from '../../assets/images/landing/client-area.svg';
import { ReactComponent as URLCheck } from '../../assets/images/landing/url-check.svg';
import { ReactComponent as Notifications } from '../../assets/images/landing/notifications.svg';
import { ReactComponent as LoginIllustration } from '../../assets/images/landing/login.svg';
import { ReactComponent as NewEntry } from '../../assets/images/landing/new-entry.svg';
import { ReactComponent as Laptop } from '../../assets/images/landing/laptop.svg';
import { ReactComponent as MoonIcon } from '../../assets/images/moon.svg';
import { ReactComponent as SunIcon } from '../../assets/images/sun.svg';
import { ReactComponent as Wave } from '../../assets/images/landing/wave.svg';
import headerBG from '../../assets/images/header_bg.png';
import headerBGMobile from '../../assets/images/header_bg_mobile.png';
import logo from '../../assets/images/landing/footer-logo.png';
import { ColorModeContext } from '../../context/color-mode.context';

import { CSSTransition } from 'react-transition-group';
import './home.scss'

import Login from '../auth/login/login.component';
import Register from '../auth/register/register.component';

const Home = () => {

	const token = useSelector((state) => state.user.currentUser.token);
	const {colorMode, setColorMode, colorModeIcon, setColorModeIcon} = useContext(ColorModeContext);

	const [ showLoginPopup, setShowLoginPopup ] = useState(false);
	const [ showRegisterPopup, setShowRegisterPopup ] = useState(false);

	const handleColorMode = () => {
		if ( colorMode === 'dark' )
		{
			setColorMode('light');
			setColorModeIcon(<MoonIcon />);
		} else {
			setColorMode('dark');
			setColorModeIcon(<SunIcon />);
		}
	}

	// get width of the window
	function getWindowDimensions() {
		const { innerWidth: width, innerHeight: height } = window;
		return { width, height };
	}
		
	function useWindowDimensions() {
		const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
		
		useEffect(() => {
			function handleResize() {
			setWindowDimensions(getWindowDimensions());
			}
		
			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}, []);
		
		return windowDimensions;
	}

	const { width } = useWindowDimensions();

	return (
		<Fragment>
			<nav>
				<div className="wrapper">
					<div className="logo">
						<LogoText fill="#fff" />
					</div>
					{ (token && token !== null && token !== undefined) ? (
						<ul>
							<li><Link className='button blue-effect' to='/dashboard'>Dashboard</Link></li>
							<li><Link to='#' onClick={handleColorMode}>{colorModeIcon}</Link></li>
						</ul>
						) : (
						<ul>
							<li><div className='button btn-primary' onClick={() => {setShowRegisterPopup(false); setShowLoginPopup(true);}}>Login</div></li>
							<li><div onClick={() => {setShowRegisterPopup(true); setShowLoginPopup(false);} }>Register</div></li>
							<li><Link to='#' onClick={handleColorMode}>{colorModeIcon}</Link></li>
						</ul>
					) }
				</div>
			</nav>
			<header className='landing'>
				<div className='wave'>
					<Wave />
				</div>
				<div className="bg">
				{
					width > 480 ? ( <img src={headerBG} alt="Hero Image" /> ) : ( <img src={headerBGMobile} alt="Hero Image" /> ) }
				</div>
				<div className="wrapper">
					<div className="content">
						<div className="row">
							<div className="col-sm-12 col-lg-6">
								<h1>Protector of your <span>network realm</span>.</h1>
								<p>Security guarding service that perform security checks on queried websites or IPs.</p>
								<Link to='/login' className="button red-effect mt-3">GET STARTED</Link>
							</div>
							<div className="col-sm-12 col-lg-6 text-right mt-sm-0 mt-5 mt-lg-0">
								<Stats />
							</div>
						</div>
					</div>
				</div>
			</header>

			<section className='features'>
				<div className="wrapper">
					<div className="row">
						<div className="col-sm-12 col-lg-4">
							<div className="icon"><ClientArea /></div>
							<h4>Client Area</h4>
							<p>We have prepared a nice, clean, simple and modern client area with support of light/dark mode.</p>
						</div>

						<div className="col-sm-12 col-lg-4 mt-5 mt-lg-0">
							<div className="icon"><URLCheck /></div>
							<h4>URL/IPs Check</h4>
							<p>Either you put an URL or IP Address, we certainly do a core malware check on both entries.</p>
						</div>

						<div className="col-sm-12 col-lg-4 mt-5 mt-lg-0">
							<div className="icon"><Notifications /></div>
							<h4>Notifications</h4>
							<p>With our implemented hook you can add your email/web address and receive status changes on entries on a daily base.</p>
						</div>
					</div>
				</div>
			</section>


			<section className='get-started'>
				<div className="wrapper">
					<div className="row align-items-center">
						<div className="col-sm-12 col-lg-6">
							<h2>Get started<br />
							with the<br />
							dashboard</h2>
							<p>With just a few clicks you can create a Heimdall account and start using it.</p>
						</div>
						<div className="col-sm-12 col-lg-6">
							<div className='illustration-circle'>
								<LoginIllustration />
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className='how-it-works'>
				<div className="wrapper">
					<div className="row align-items-center">
						<div className="col-sm-12 col-lg-12 text-left text-lg-center mb-5 title">
							<h2>How does it work?</h2>
							<p className="mx-auto">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores </p>
						</div>
						<div className="col-sm-12 col-lg-6">
							<div className='illustration-circle-left'>
								<NewEntry />
							</div>
						</div>
						<div className="col-sm-12 col-lg-6">
							<ul className='numbered-list'>
								<li className='mt-2'>
									<h4>New Entry</h4>
									<p className='mt-2'>Start with inserting your wanted URL or IP and let us start the process of checking.</p>
								</li>
								<li className='mt-2'>
									<h4>URL Check Status</h4>
									<p className='mt-2'>In just a few mileseconds, receive the main stats of your entered record.</p>
								</li>
								<li className='mt-2'>
									<h4>Detailed View</h4>
									<p className='mt-2'>We prepared a detailed page displaying all checks and statuses for every record.</p>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>


			<section className='landing-dashboard'>
				<div className="wrapper">
					<div className="row align-items-center">
						<div className="col-sm-12 col-lg-12 text-left text-lg-center mb-5 title">
							<h2>How does it work?</h2>
							<p className="mx-auto">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores </p>
							<a href="/login" className='button blue mt-3'>Get Started</a>
						</div>
						
					</div>
				</div>
			</section>


			<footer>
				<div className="wrapper text-center">
					<div className="image-wrap text-center">
						<Laptop />
					</div>
					<img src={logo} alt='Logo' />
					<p className='mt-3 text-white'>Copyright © 2020. All Rights Reserved.</p>
				</div>
			</footer>

						
			<CSSTransition in={showLoginPopup} timeout={0} classNames="fade">
				<div className='popup' onClick={ () => setShowLoginPopup(false) }>
					<div className="popup-container" onClick={e => e.stopPropagation()}>
						<Login />
					</div>
				</div>
			</CSSTransition>


			<CSSTransition in={showRegisterPopup} timeout={0} classNames="fade">
				<div className='popup' onClick={ () => setShowRegisterPopup(false) }>
					<div className="popup-container" onClick={e => e.stopPropagation()}>
						<Register />
					</div>
				</div>
			</CSSTransition>
		</Fragment>
	);
}

export default Home;