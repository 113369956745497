import { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';

// Importing usable components
import Input from '../input/input.component';
import Entry from './entry.component';
import NewEntry from './new-entry.component';
import Loader from '../loader/loader.component';
import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';

// Importing style for entries
import './entries.style.scss';


import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { EntriesContext } from '../../context/entries.context';
import { LoaderContext } from '../../context/loader.context';
import { toast } from 'react-toastify';

const Entries = () => {
	const ITEMS_PER_PAGE = 10;

	/**************************************************************************/
	// React router dom navigate
	/**************************************************************************/
	const navigate = useNavigate()


	/**************************************************************************/
	// Redux
	/**************************************************************************/
	const toggleNewEntry = useSelector((state) => state.entries.toggleNewEntry);
	const entries = useSelector((state) => state.entries.entries);


	/**************************************************************************/
	// Context
	/**************************************************************************/
	const { currentPage, setCurrentPage, checkedEntries, setCheckedEntries, deleteEntry, filteredEntries, setFilteredEntries, searchedEntries, setSearchedEntries, setBoxFilteredEntries, boxFilteredEntries, setAddressType } = useContext(EntriesContext);
	const { fetchingEntriesLoader } = useContext(LoaderContext);
	// const { boxActive, setBoxActive } = useContext(LoaderContext);



	/**************************************************************************/
	// State Declaration
	/**************************************************************************/
	const [searchInputValue, setSearchInputValue] = useState('');
	const [checkAllInput, setCheckAllInput] = useState(false);
	const [sortBy, setSortBy] = useState(false);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);
	const [subheadingMessage, setSubheadingMessage] = useState('All Entries');



	/**************************************************************************/
	// On entries change, add all entries to filteredEntries state
	/**************************************************************************/
	useEffect(() => {
		setFilteredEntries(entries);
		setSearchedEntries(entries);
		setBoxFilteredEntries(entries);
	}, [entries]);


	/**************************************************************************/
	// State for input
	/**************************************************************************/
	const handleChange = async (e) => {
		setSearchInputValue(e.target.value);
	}


	/**************************************************************************/
	// search input on changed filter filteredEntries state
	/**************************************************************************/
	useEffect(() => {
		entries.length && setFilteredEntries(searchedEntries.filter(entry => 
			entry.entry.toLowerCase().includes(searchInputValue.toLowerCase())
		))
	}, [searchInputValue]);



	/**************************************************************************/
	// if all checked select "All" Checkbox
	/**************************************************************************/
	useEffect(() => {
		if ( checkedEntries.length > 0 && checkedEntries.length === filteredEntries.length)
			setCheckAllInput(true)
		else
			setCheckAllInput(false);
	}, [checkedEntries, entries.length]); 



	/**************************************************************************/
	// Handle check all checkbox
	/**************************************************************************/
	const handleCheckAllEntries = (e) => {
		setCheckAllInput(!checkAllInput);
		setCheckedEntries(filteredEntries.map((entry) => {
			return entry.uuid
		}));
		if ( checkAllInput )
			setCheckedEntries([]);
	}


	/**************************************************************************/
	// Handle check all checkbox
	/**************************************************************************/
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			navigate('/monitoring/'+filteredEntries[0].uuid);
		}
	}



	/**************************************************************************/
	// Handle single entry click
	/**************************************************************************/
	const handleClickSingleEntry = (e) => {
		e.stopPropagation();
		const { id, checked } = e.target;

		setCheckedEntries([id, ...checkedEntries]);

		if (!checked) {
			setCheckedEntries(checkedEntries.filter(item => item !== id));
		}
	}


	/**************************************************************************/
	// Handle deleting entries
	/**************************************************************************/
	const confirmDeleteEntries = () => {
		
		if ( checkedEntries.length > 0 && checkedEntries.length < 3 )
		{
			checkedEntries.map((uuid) => deleteEntry(uuid));

			setCheckedEntries([]);
		}
		else if ( checkedEntries.length >= 3 )
		{
			setShowConfirmPopup(true);
		}

	}


	/**************************************************************************/
	// Handle deleting entries
	/**************************************************************************/
	const handleDeleteEntries = () => {

		checkedEntries.map((uuid) => deleteEntry(uuid));
		toast.success('Entries successfully deleted');
		
		setShowConfirmPopup(false);
		setCheckedEntries([]);
	}

	
	const handleResetSearch = () => {
		setFilteredEntries(entries);
		setSearchInputValue('');
	}


	/**************************************************************************/
	// Handle all sorts
	/**************************************************************************/
	const handleFilterNumber = (e, key) => {
		
		if ( e.currentTarget.classList.contains('active') ) {
			e.currentTarget.classList.remove('active')
			setFilteredEntries([...entries]);
		} else {
			let elems = document.querySelectorAll('.tr div.active');
			elems.forEach(elem => elem.classList.remove('active'));

			e.currentTarget.classList.add('active')
						
			const newFilteredEntry = filteredEntries.sort((a, b) => {
				switch (key) {
					case 'harmless':
						if ( parseInt(a.data?.stats?.harmless) < parseInt(b.data?.stats?.harmless) ) 
							return 1;
						else if ( parseInt(a.data?.stats?.harmless) > parseInt(b.data?.stats?.harmless) ) 
							return -1;
						else return 0
	
					case 'suspicious':
						if ( parseInt(a.data?.stats?.suspicious) < parseInt(b.data?.stats?.suspicious) ) 
							return 1;
						else if ( parseInt(a.data?.stats?.suspicious) > parseInt(b.data?.stats?.suspicious) ) 
							return -1;
						else return 0;
	
					case 'malicious':
						if ( parseInt(a.data?.stats?.malicious) < parseInt(b.data?.stats?.malicious) ) 
							return 1;
						else if ( parseInt(a.data?.stats?.malicious) > parseInt(b.data?.stats?.malicious) ) 
							return -1;
						else return 0;
					
					case 'undetected':
						if ( parseInt(a.data?.stats?.undetected) < parseInt(b.data?.stats?.undetected) ) 
							return 1;
						else if ( parseInt(a.data?.stats?.undetected) > parseInt(b.data?.stats?.undetected) ) 
							return -1;
						else return 0;
					
					case 'updated':
						if ( parseInt(moment(a.created_at).format('x')) < parseInt(moment(b.created_at).format('x')) ) 
							return 1;
						else if ( parseInt(moment(a.created_at).format('x')) > parseInt(moment(b.created_at).format('x')) ) 
							return -1;
						else
							return 0;

					default:
						break;
				}
			});
			//set state
			setFilteredEntries([...newFilteredEntry]);
		}
	}


	// Pagination
	const [ pagination, setPagination ] = useState({
		maxPage: 0,
		currentPage: currentPage,
		itemsPerPage: ITEMS_PER_PAGE,
		entries: []
	});

	// Set pagination information when states are loaded
	useEffect( () => {
		if ( entries.length > 0 && entries.length > pagination.itemsPerPage )
		{
			setPagination({
				...pagination,
				maxPage: Math.ceil(filteredEntries.length / pagination.itemsPerPage),
				entries: filteredEntries.slice(0, pagination.itemsPerPage),
			});
		} else {
			setPagination({
				...pagination,
				entries: filteredEntries,
			});
		}
	}, [entries]);

	// Handle pagination page change 
	const handlePaginationChange = (page) => {
		let sliceTo = page * pagination.itemsPerPage;
		let sliceFrom = sliceTo - pagination.itemsPerPage;

		setPagination({
			...pagination,
			currentPage: page,
			entries: filteredEntries.slice(sliceFrom, sliceTo),
		});

		setCurrentPage(page);
	}


	// Handle sorting by IP/URL Address
	const handleSortAddress = (e) => {
		if ( e.target.value === 'url' )
		{
			setAddressType('url');
			setSubheadingMessage('Domains')
			setFilteredEntries(boxFilteredEntries.filter(entry => {
				if ( validator.isURL(entry.entry) && !validator.isIP(entry.entry) )
					return entry;
			}))
		} else if ( e.target.value === 'ip' )
		{
			setAddressType('ip');
			setSubheadingMessage('IPs & Subnets')
			setFilteredEntries(boxFilteredEntries.filter(entry => {
				if ( validator.isIP(entry.entry) )
					return entry;
			}))
		} else {
			setAddressType('all');
			setSubheadingMessage('All Entries')
			setFilteredEntries(boxFilteredEntries);
		}
	}


	useEffect(()=>{
		if ( filteredEntries.length > 0 && filteredEntries.length > pagination.itemsPerPage )
		{
			let sliceTo = currentPage * pagination.itemsPerPage;
			let sliceFrom = sliceTo - pagination.itemsPerPage;

			setPagination({
				...pagination,
				currentPage: currentPage,
				maxPage: Math.ceil(filteredEntries.length / pagination.itemsPerPage),
				entries: filteredEntries.slice(sliceFrom, sliceTo),
			});
		} else {
			setPagination({
				...pagination,
				currentPage: currentPage,
				maxPage: 0,
				entries: filteredEntries,
			});
		}
	}, [filteredEntries])

	// HTML
	return (
		<Fragment>
			<section className='entries'>
				<div className='wrapper'>
					<div className='row align-items-center justify-content-between'>
						<div className='col-4 col-sm-4 col-md-2'>
							<h5 className='subheading mb-0'>{subheadingMessage}</h5>
						</div>
						<div className='col-8 col-sm-8 col-md-4 text-right'>
							<div className="sort-by">
								<p>Show</p>

								<div>
									<select name="" id="" onChange={handleSortAddress}>
										<option value="all">All</option>
										<option value="ip">IPs & Subnets</option>
										<option value="url">Domains</option>
									</select>
								</div>
							</div>
						</div>
						<div className='col-sm-12 col-md-6 text-right mt-3 mt-md-0 mt-lg-0'>
							<Input placeholder='Search Entries...' type="text" onChange={handleChange} onKeyDown={handleKeyDown} value={searchInputValue} autoComplete="off" />
						</div>
					</div>

					<div className='entries-table'>
						<div className='head'>
							<div className="tr">
								<div className="entry-wrap">
									<div className='check'>
									{ entries.length > 0 && (
										<div><input onChange={handleCheckAllEntries} checked={checkAllInput} type="checkbox" className='checkbox' id='select-all' />
										<label htmlFor='select-all'></label></div>
									)}
									</div>
									<div className='location'>Location</div>
									<div className='status'>Status</div>
									<div className='harmless' onClick={(e) => { setSortBy(!sortBy); handleFilterNumber( e, 'harmless' )}}>Harmless</div>
									<div className='suspicious' onClick={(e) => handleFilterNumber( e, 'suspicious' )}>Suspicious</div>
									<div className='malicious' onClick={(e) => handleFilterNumber( e, 'malicious' )}>Malicious</div>
									<div className='undetected' onClick={(e) => handleFilterNumber( e, 'undetected' )}>Undetected</div>
									<div className='updated' onClick={(e) => handleFilterNumber( e, 'updated' )}>Updated</div>
									<div className='trash'>
										{ checkedEntries.length > 0 && <div className='trash-icon' onClick={confirmDeleteEntries}><TrashIcon /></div> }
									</div>
								</div>
							</div>
						</div>

						<div className={`body ${fetchingEntriesLoader ? `loading` : ``}`}>
							
							<CSSTransition in={toggleNewEntry} timeout={300} classNames="fade">
								<NewEntry />
							</CSSTransition>
							
							<TransitionGroup>
							{
								filteredEntries.length > 0 && pagination.entries.map((entry) => (
									<CSSTransition key={entry.uuid} timeout={0} classNames="fade">
										<Entry key={entry.uuid} record={entry} isChecked={checkedEntries.includes(entry.uuid)} handleClick={handleClickSingleEntry} />
									</CSSTransition>
								))
							}
							</TransitionGroup>

							{ pagination.maxPage ? (
							<ul className="pagination mt-5">
								<li className={`paginate_button page-item previous ${pagination.currentPage === 1 ? 'disabled' : ''}`} id="kt_table_users_previous">
									<div onClick={() => handlePaginationChange(pagination.currentPage-1)} className="page-link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
									</div>
								</li>
								{
									[...Array(pagination.maxPage)].map( (x, i) =>
										<li key={i} className={`paginate_button page-item ${ pagination.currentPage === i+1 ? 'active' : '' }`}>
											<div onClick={() => handlePaginationChange(i+1)} className="page-link">{i+1}</div>
										</li>
									)
								}
								<li className={`paginate_button page-item next ${pagination.currentPage === pagination.maxPage ? 'disabled' : ''}`} id="kt_table_users_next">
									<div onClick={() => handlePaginationChange(pagination.currentPage+1)} className="page-link">
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
									</div>
								</li>
							</ul>
							) : ''}

							
							{ fetchingEntriesLoader && <Loader className='absolute' />}

							{
								// check if user don't have entries
								!fetchingEntriesLoader && !entries.length && (<div className='empty-message'>You have no entries..</div>)
							}
							{
								// searching no result
								entries.length > 0 && !filteredEntries.length && (<div className='empty-message'>No entries found</div>)
							}

						</div>
					</div>
				</div>
			</section>

			<CSSTransition in={showConfirmPopup} timeout={0} classNames="fade">
				<div className="popup" onClick={ () => setShowConfirmPopup(false) }>
					<div className="popup-container text-center" onClick={e => e.stopPropagation()}>
						<h4>Confirm delete</h4>
						<p>Are you sure you are want to remove {checkedEntries.length} entries?</p>

						<div className='mt-3'>
							<button className='button button-xs red mr-4' onClick={handleDeleteEntries}>Yes</button>
							<button className='button button-xs blue' onClick={() => {setShowConfirmPopup(false)}}>No</button>
						</div>
					</div>
				</div>
			</CSSTransition>
		</Fragment>
	);
}

export default Entries;