import { ACTION_TYPES } from './user.types';

export const setCurrentUser = (user) => {
	return { type: ACTION_TYPES.SET_CURRENT_USER, payload: user };
}

export const removeCurrentUser = () => {
	return { type: ACTION_TYPES.REMOVE_CURRENT_USER, payload: '' };
}

export const setUserHooks = (settings) => {
	return { type: ACTION_TYPES.SET_USER_HOOKS, payload: settings };
}

export const setUserHookLogs = (logs) => {
	return { type: ACTION_TYPES.SET_USER_HOOK_LOGS, payload: logs };
}