import { Fragment, useContext } from "react";
import { Link, Outlet } from "react-router-dom";

// Auth Style css
import './auth.style.scss';

// Importing icons
import { ReactComponent as SecurityCheck } from '../../assets/images/security-check.svg';
import { ReactComponent as LogoSymbol } from '../../assets/images/logo-symbol.svg';
import { ReactComponent as LogoText } from '../../assets/images/logo-text.svg';
import darkBG from '../../assets/images/auth-background-dark.png';
import lightBG from '../../assets/images/auth-background.png';

import { ColorModeContext } from "../../context/color-mode.context";

const AuthIllustration = () => {

	const { colorMode } = useContext(ColorModeContext);

	const bgStyle = {
		backgroundImage: (colorMode === 'dark') ? `url(${darkBG})` : `url(${lightBG})`,
	};

	return (
		<Fragment>
			<main className="auth" style={bgStyle}>
				<div className="auth-illustration">
					<div className="auth-illustration-wrap">
						<SecurityCheck />
						<h2>Security guarding service</h2>
						<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
					</div>
				</div>
				<div className="auth-content">
					<Link to='/'>
						<div className="auth-content_logo">
							<div className="auth-content_logo__symbol">
								<LogoSymbol />
							</div>
							<div className="auth-content_logo__text">
								<LogoText fill={(colorMode === 'dark') ? '#fff' : '#000'} />
							</div>
						</div>
					</Link>
					<div className="auth-content-wrap">
						<Outlet />
					</div>
				</div>
			</main>
		</Fragment>
	);
}

export default AuthIllustration;