import { useNavigate } from "react-router-dom";
import Button from '../../components/button/button.component';
import { ReactComponent as NotFoundLight } from '../../assets/images/not-found.svg';
import { ReactComponent as NotFoundDark } from '../../assets/images/not-found-dark.svg';
import './not-found.style.scss';
import { useContext } from "react";
import { ColorModeContext } from "../../context/color-mode.context";

const NotFound = () => {

	const { colorMode } = useContext(ColorModeContext);

	const Illustration = () => {
		if ( colorMode === 'dark' ) return <NotFoundDark />;
		
		return <NotFoundLight />;
	}

	const navigate = useNavigate();

	const handleReturnHome = () => {
		navigate('/dashboard')
	}

	return (
		<div className="not-found">
			<Illustration />
			<h3 className='mb-4'>Page Not Found</h3>
			<Button buttonType='blue' onClick={handleReturnHome}>Return Home</Button>
		</div>
	);
}

export default NotFound;