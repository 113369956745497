import { ACTION_TYPES } from './entries.types';

export const setToggleNewEntry = (bool) => {
	return { type: ACTION_TYPES.TOGGLE_NEW_ENTRY, payload: bool };
}

export const addNewEntry = (entry) => {
	return { type: ACTION_TYPES.ADD_NEW_ENTRY, payload: entry };
}

export const removeEntry = (entry) => {
	return { type: ACTION_TYPES.REMOVE_ENTRY, payload: entry };
}

export const setEntries = (entries) => {
	return { type: ACTION_TYPES.SET_ENTRIES, payload: entries };
}

export const addNewEntryInformation = (entry) => {
	return { type: ACTION_TYPES.ADD_NEW_ENTRY_INFORMATION, payload: entry };
}

export const setEntriesPagination = (data) => {
	return { type: ACTION_TYPES.SET_ENTRIES_PAGINATION, payload: data };
}

export const setStats = (data) => {
	return { type: ACTION_TYPES.SET_STATS, payload: data };
}
