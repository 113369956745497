import { useState, createContext } from "react";


export const LoaderContext = createContext({
    loader: false,
    setLoader: () => null,
    screenLoader: false,
    setScreenLoader: () => null,
    boxActive: false,
    setBoxActive: () => null,
    addedEntryLoader: false,
    setAddedEntryLoader: () => null,
    fetchingEntriesLoader: false,
    setFetchingEntriesLoader: () => null,
    newHookLoader: false,
    setNewHookLoader: () => null,
});


export const LoaderProvider = ({ children }) => {
    const [loader, setLoader] = useState(false);
    const [screenLoader, setScreenLoader] = useState(false);
	const [ boxActive, setBoxActive ] = useState('');
	const [ addedEntryLoader, setAddedEntryLoader ] = useState(false);
	const [ fetchingEntriesLoader, setFetchingEntriesLoader ] = useState(false);
	const [ newHookLoader, setNewHookLoader ] = useState(false);
	const [ recheckEntryLoader, setRecheckEntryLoader ] = useState(false);

	const value = { loader, setLoader, screenLoader, setScreenLoader, boxActive, setBoxActive, addedEntryLoader, setAddedEntryLoader, fetchingEntriesLoader, setFetchingEntriesLoader, newHookLoader, setNewHookLoader, recheckEntryLoader, setRecheckEntryLoader };
	return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};
